export const ArrowForward = () => {
  return (
    <svg
      width='19'
      height='19'
      viewBox='0 0 19 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.01318 9.61865H17.145'
        stroke='white'
        strokeWidth='2.26977'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.8488 4.32251L17.1449 9.61862L11.8488 14.9147'
        stroke='white'
        strokeWidth='2.26977'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
