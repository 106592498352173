import { FieldRenderProps } from 'react-final-form';
import Select from 'react-select';
import '../lib.scss';
import React from 'react';

type Props<T> = FieldRenderProps<T, HTMLInputElement>;

export const InputSelectCountry = <T extends string>(props: Props<T>) => {
  const customStyles = {
    control: () => ({
      // none of react-select's styles are passed to <Control />
      //  width:props.width?props.width:400,
      // minHeight:props.height?props.height:55,
      borderRadius: 8,
      textAlign: 'start' as const,
      borderStyle: 'solid',
      borderWidth: 1,
      display: 'flex',
      borderColor: '#cccccc',
      height: 55,
      paddingInlineStart: 8,
    }),
  };
  const { meta, input } = props;

  // var iconShow = false;
  return (
    <>
      <Select
        styles={customStyles}
        className='selectCountry'
        options={props.options}
        onChange={input.onChange}
        value={input.value}
        placeholder={props.placeholder}
      />
      {(meta.touched && meta.error) ||
        (meta.submitError && (
          <div className='submitError'>{meta.error || meta.submitError}</div>
        ))}
    </>
  );
};
