import { Field, Form } from 'react-final-form';
import { BottomBarRegistration } from '../../lib/final-form/bottom-bar-registration';
import { TopBarRegistration } from '../../lib/final-form/top-bar-registration';
import banner12 from '../../images/banner12.png';
import './registration.scss';
import { checkbox } from '../../lib/final-form/checkbox';
import { useEffect, useState } from 'react';
import { RegistrationEducationDegreeForm } from '../../stores/registration-store';
import { observer } from 'mobx-react';
import { useAgentStore } from '../../stores/use-agent-store';
import { useHistory } from 'react-router-dom';
import I18n from '../../lib/lingui/i18n';
import logoButton from '../../images/content.png';
import {
  educationDegreesKeys,
  educationDegreesVals,
} from '../../globals/professional-information';

export const RegistrationEducationDegree = observer(() => {
  const { registrationStore } = useAgentStore();

  const history = useHistory();
  const educationDegrees: {
    id: number;
    name: string;
    text: string;
    value: string;
  }[] = [
    { id: 1, name: 'none', text: 'None', value: 'None' },
    {
      id: 2,
      name: 'master',
      text: 'Master’s degree',
      value: 'Master’s degree',
    },
    { id: 3, name: 'professor', text: 'Professor', value: 'Professor' },
    {
      id: 4,
      name: 'bachelor',
      text: 'Bachelor’s degree',
      value: 'Bachelor’s degree',
    },
    { id: 5, name: 'dr', text: 'Dr.', value: 'Dr.' },
  ];
  const [educationDegree, setEducationDegree] = useState(String);
  const [languageDirection, setLanguageDirection] = useState<string>();

  useEffect(() => {
    if (
      window.location.pathname.includes('he') ||
      window.location.pathname.includes('ar')
    ) {
      setLanguageDirection('rtl');
    } else {
      setLanguageDirection('lts');
    }
    if (!registrationStore.callAgentOnboarding)
      registrationStore.getCallAgentOnBoarding().then(() => {
        if (registrationStore.callAgentOnboarding) {
          const education1 =
            educationDegreesVals[
              educationDegreesKeys.indexOf(
                registrationStore.callAgentOnboarding.educationDegree,
              )
            ];
          if (education1) {
            setEducationDegree(education1);
          } else {
            setEducationDegree('None');
          }
        }
      });
    let education;
    if (registrationStore.callAgentOnboarding)
      education =
        educationDegreesVals[
          educationDegreesKeys.indexOf(
            registrationStore.callAgentOnboarding.educationDegree,
          )
        ];
    if (education) {
      setEducationDegree(education);
    } else {
      setEducationDegree('None');
    }
  }, [registrationStore]);

  function onchange(text: string) {
    if (educationDegree === text) {
      setEducationDegree('');
    } else {
      setEducationDegree(text);
    }
  }
  return (
    <div>
      <Form<RegistrationEducationDegreeForm>
        initialValues={registrationStore.registrationEducationDegreeForm}
        onSubmit={async (form: any) => {
          switch (form.formButton) {
            case 'next': {
              const response = await registrationStore.updateCallAgent({
                educationDegree:
                  educationDegree === 'None' ? null : educationDegree,
              });
              if (response && response.errors) {
                return response;
              }
              return history.push(
                `/${window.location.pathname.slice(1, 3)}/registration/certificates`,
              );
            }
            case 'back': {
              return history.push(
                `/${window.location.pathname.slice(1, 3)}/registration/social-media`,
              );
            }
            case 'exit': {
              const response = await registrationStore.updateCallAgent({
                educationDegree: educationDegree,
              });
              if (response && response.errors) {
                return response;
              }
              return history.push(`/${window.location.pathname.slice(1, 3)}`);
            }
          }
        }}
      >
        {({ submitError, handleSubmit, form }) => (
          <form
            onSubmit={handleSubmit}
            className={
              languageDirection === 'rtl'
                ? 'registrationBodyRtl'
                : 'registrationBody'
            }
          >
            <img
              src={banner12}
              className='banner'
              id='banner12'
              alt={'banner12'}
            />
            <img
              className={
                languageDirection === 'rtl' ? 'logoButtonRtl' : 'logoButton'
              }
              src={logoButton}
              alt={'logoButton'}
              onClick={() => {
                history.push('/');
              }}
            />
            <div
              className={
                languageDirection === 'rtl' ? 'formSideRtl' : 'formSide'
              }
            >
              <TopBarRegistration
                title='Professional information'
                subTitle='Education degree'
                withMenu={true}
                withExit={true}
                clickExit={() => form.change('formButton', 'exit')}
              />
              <div className='dataAreaWide'>
                <div className='header'>
                  <I18n t='Add education degree' />
                </div>
                <div className='subHeader'>
                  <I18n t='You can select just one education degree' />
                </div>
                <div className='flexForm'>
                  <div
                    className={
                      languageDirection === 'rtl'
                        ? 'marginSide marginLeftRtl'
                        : 'marginSide'
                    }
                  >
                    {educationDegrees.map((item, index) =>
                      index < 3 ? (
                        <div key={item.id}>
                          <Field
                            disabled={
                              educationDegree !== item.text &&
                              educationDegree !== ''
                            }
                            name={item.name}
                            id={item.id}
                            inputType={'checkbox'}
                            inputClass='smallInputText checkboxPadding'
                            text={item.text}
                            onchange={() => onchange(item.value)}
                            checked={educationDegree === item.text}
                            component={checkbox}
                            type='checkbox'
                          />
                        </div>
                      ) : null,
                    )}
                  </div>
                  <div className='itemRight'>
                    {educationDegrees.map((item, index) =>
                      index > 2 ? (
                        <div key={item.id}>
                          <Field
                            disabled={
                              educationDegree !== item.text &&
                              educationDegree !== ''
                            }
                            name={item.name}
                            id={item.id}
                            inputType={'checkbox'}
                            inputClass='smallInputText checkboxPadding'
                            text={item.text.replaceAll('.', '')}
                            onchange={() => onchange(item.value)}
                            checked={educationDegree === item.text}
                            component={checkbox}
                            type='checkbox'
                          />
                        </div>
                      ) : null,
                    )}
                  </div>
                </div>
                {submitError && (
                  <div className='submitError'>
                    <I18n t={submitError} />
                  </div>
                )}
              </div>
              <BottomBarRegistration
                buttonNextapprove={educationDegree ? 'approve' : null}
                withBack={true}
                clickBack={() => form.change('formButton', 'back')}
                clickNext={() => form.change('formButton', 'next')}
              />
            </div>
          </form>
        )}
      </Form>
    </div>
  );
});
