import { FieldRenderProps } from 'react-final-form';
import Dropdown from 'react-dropdown';
import '../lib.scss';
import React from 'react';

type Props<T> = FieldRenderProps<T, HTMLInputElement>;

export const InputSelect = <T extends string>(props: Props<T>) => {
  const { meta, input } = props;
  const languageDirection =
    window.location.pathname.includes('he') ||
    window.location.pathname.includes('ar')
      ? 'rtl'
      : 'ltr';

  // var iconShow = false;
  return (
    <>
      <div
        className={
          props.inputClass ? `${props.inputClass} flex` : 'inputText flex'
        }
      >
        <div className='inputItem'>
          {input.value && (
            <div
              className={
                languageDirection === 'rtl'
                  ? 'smallPlaceholder smallPlaceholderRtl'
                  : 'smallPlaceholder'
              }
            >
              {props.placeholder}
            </div>
          )}
          <Dropdown
            controlClassName={input.value !== '' ? '' : 'inputfiledSelect'}
            // controlClassName={input.value !== "" ? '' : (iconShow = true, 'inputfiledSelect')}
            menuClassName={props.menuClassName}
            // controlClassName={props.inputClass ? props.inputClass : "inputText"}
            placeholderClassName={
              input.value ? 'valueSelect' : 'placeholderSelect'
            }
            options={props.options}
            onChange={input.onChange}
            value={input.value}
            placeholder={props.placeholder}
          />
        </div>
        {props.icon && (
          <div className='iconStyle inputItemRight'>{props.icon}</div>
        )}
      </div>
      {(meta.touched && meta.error) ||
        (meta.submitError && (
          <div className='submitError'>{meta.error || meta.submitError}</div>
        ))}
    </>
  );
};
