import '../lib.scss';
import { SideBarMenu } from './side-bar-menu';
import I18n from '../lingui/i18n';

export const TopBarRegistration = (props: any) => {
  return (
    <>
      <div className='topBarRegistration'>
        {props.withMenu === true && (
          <div className='item'>
            <SideBarMenu title={props.title} subTitle={props.subTitle} />
          </div>
        )}
        <div className='item topBarRegistrationTitle'>
          {props.title && <I18n t={props.title} />}
        </div>
        {props.withExit === true && (
          <div className='item itemButton'>
            <button
              className='topBarRegistrationButton'
              onClick={props.clickExit}
            >
              <I18n t='Exit' />
            </button>
          </div>
        )}
      </div>
    </>
  );
};
