import { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { RegistrationAboutYou } from './screens/registration/registration-about-you';
import { RegistrationAgentType } from './screens/registration/registration-agent -type';
import { RegistrationBecomeAnAgent } from './screens/registration/registration-become-agent';
import { RegistrationLanguage } from './screens/registration/registration-language';
import { RegistrationLocation } from './screens/registration/registration-location';
import { RegistrationPhone } from './screens/registration/registration-phone';
import { RegistrationPreferredWorkplace } from './screens/registration/registration-preferred-workplace';
import { RegistrationShortDescription } from './screens/registration/registration-short-description';
import { RegistrationSignup } from './screens/registration/registration-signup';
import { RegistrationSms } from './screens/registration/registration-sms';
import { RegistrationWorkExperience } from './screens/registration/registration-work-experience';
import { SocialMedia } from './screens/registration/registration.socialMedia';
import { RegistrationHobbies } from './screens/registration/registration-hobbies';
import { RegistrationEducationTitle } from './screens/registration/registration-education-title';
import { RegistrationProfession } from './screens/registration/registration-profession';
import { RegistrationCertificates } from './screens/registration/registration-certificates';
import { RegistrationCongratulations } from './screens/registration/registration-congratulations';
import { RegistrationEducationDegree } from './screens/registration/registration-education-degree';
import { RegistrationPreInterview } from './screens/registration/registration-pre-interview';
import { RegistrationInterview } from './screens/registration/registration-interview';
import { LandingPage } from './screens/landing-page/landing-page';
import { TermsAndCondition } from './screens/web-site/termsAndCondition';
import { PrivicyPolicy } from './screens/web-site/privicyPolicy';
import { IPostalOne } from './screens/iPostalOne/i-postal-one.tsx';
import { Expo } from './screens/iPostalOne/expo';

// const baseRouteUrl = "/:locale(he|en)?";
const base = '/:locale(he|en|ar|hu|gr|ru|es|fr|de|nl|pt)';
export const locales = [
  'en',
  'he',
  'ar',
  'hu',
  'gr',
  'ru',
  'es',
  'fr',
  'de',
  'nl',
  'pt',
];

// export const baseUrl = i18n.language === 'en' ? '/en' : '/he';
// import { RegistrationInterview } from './screens/registration/registration-interview';
const navLang = `${window.navigator.language.substring(0, 2)}`;
const routing = (
  <Router>
    <div>
      <Switch>
        <Route
          path={`${base}/registration/verify-phone`}
          component={RegistrationPhone}
        />
        <Route
          path={`${base}/registration/verify-sms`}
          component={RegistrationSms}
        />
        <Route
          path={`${base}/registration/sign-up`}
          component={RegistrationSignup}
        />
        <Route
          path={`${base}/registration/become-an-agent`}
          component={RegistrationBecomeAnAgent}
        />
        <Route
          path={`${base}/registration/agent-type`}
          component={RegistrationAgentType}
        />
        <Route
          path={`${base}/registration/about-you`}
          component={RegistrationAboutYou}
        />
        <Route
          path={`${base}/registration/social-media`}
          component={SocialMedia}
        />
        <Route
          path={`${base}/registration/location`}
          component={RegistrationLocation}
        />
        <Route
          path={`${base}/registration/language`}
          component={RegistrationLanguage}
        />
        <Route
          path={`${base}/registration/short-description`}
          component={RegistrationShortDescription}
        />
        <Route
          path={`${base}/registration/profession`}
          component={RegistrationProfession}
        />
        <Route
          path={`${base}/registration/certificates`}
          component={RegistrationCertificates}
        />
        <Route
          path={`${base}/registration/work-experience`}
          component={RegistrationWorkExperience}
        />
        <Route
          path={`${base}/registration/hobbies`}
          component={RegistrationHobbies}
        />
        <Route
          path={`${base}/registration/education-title`}
          component={RegistrationEducationTitle}
        />
        <Route
          path={`${base}/registration/education-degree`}
          component={RegistrationEducationDegree}
        />
        <Route
          path={`${base}/registration/preferred-workplace`}
          component={RegistrationPreferredWorkplace}
        />
        <Route
          path={`${base}/registration/congratulations`}
          component={RegistrationCongratulations}
        />
        <Route
          path={`${base}/registration/pre-interview`}
          component={RegistrationPreInterview}
        />
        <Route
          path={`${base}/registration/interview`}
          component={RegistrationInterview}
        />
        <Route exact path={`${base}/termsAndCondition`}>
          <TermsAndCondition />
        </Route>
        <Route exact path={`/terms-condition/lang=en`}>
          <Redirect to={'/en/termsAndCondition'}></Redirect>
        </Route>
        <Route exact path={`/terms-condition/lang=ar`}>
          <Redirect to={'/ar/termsAndCondition'}></Redirect>
        </Route>
        <Route exact path={`/terms-condition/lang=gr`}>
          <Redirect to={'/gr/termsAndCondition'}></Redirect>
        </Route>
        <Route exact path={`/terms-condition/lang=he`}>
          <Redirect to={'/he/termsAndCondition'}></Redirect>
        </Route>
        <Route exact path={`/terms-condition/lang=hu`}>
          <Redirect to={'/hu/termsAndCondition'}></Redirect>
        </Route>
        <Route exact path={`/terms-condition/lang=ru`}>
          <Redirect to={'/ru/termsAndCondition'}></Redirect>
        </Route>
        <Route exact path={`/terms-condition/lang=es`}>
          <Redirect to={'/es/termsAndCondition'}></Redirect>
        </Route>
        <Route exact path={`/terms-condition/lang=de`}>
          <Redirect to={'/de/termsAndCondition'}></Redirect>
        </Route>
        <Route exact path={`/terms-condition/lang=nl`}>
          <Redirect to={'/nl/termsAndCondition'}></Redirect>
        </Route>
        <Route exact path={`/terms-condition/lang=pt`}>
          <Redirect to={'/pt/termsAndCondition'}></Redirect>
        </Route>

        <Route exact path={`/terms-condition`}>
          <Redirect to={'/en/termsAndCondition'}></Redirect>
        </Route>
        <Route exact path={`${base}/privacy-policy`}>
          <PrivicyPolicy />
        </Route>
        <Route exact path={`/privacy-policy/lang=${navLang}`}>
          <Redirect
            to={
              locales.find((lang) => lang === navLang)
                ? `/${navLang}/termsAndCondition`
                : '/en/termsAndCondition'
            }
          ></Redirect>
        </Route>
        <Route exact path={`/privacy-policy/lang=en`}>
          <Redirect to={'/en/privacy-policy'}></Redirect>
        </Route>
        <Route exact path={`/privacy-policy/lang=ar`}>
          <Redirect to={'/ar/privacy-policy'}></Redirect>
        </Route>
        <Route exact path={`/privacy-policy/lang=gr`}>
          <Redirect to={'/gr/privacy-policy'}></Redirect>
        </Route>
        <Route exact path={`/privacy-policy/lang=he`}>
          <Redirect to={'/he/privacy-policy'}></Redirect>
        </Route>
        <Route exact path={`/privacy-policy/lang=hu`}>
          <Redirect to={'/hu/privacy-policy'}></Redirect>
        </Route>
        <Route exact path={`/privacy-policy/lang=ru`}>
          <Redirect to={'/ru/privacy-policy'}></Redirect>
        </Route>
        <Route exact path={`/privacy-policy/lang=es`}>
          <Redirect to={'/es/privacy-policy'}></Redirect>
        </Route>
        <Route exact path={`/privacy-policy/lang=de`}>
          <Redirect to={'/de/privacy-policy'}></Redirect>
        </Route>
        <Route exact path={`/privacy-policy/lang=nl`}>
          <Redirect to={'/nl/privacy-policy'}></Redirect>
        </Route>
        <Route exact path={`/privacy-policy/lang=pt`}>
          <Redirect to={'/pt/privacy-policy'}></Redirect>
        </Route>

        <Route exact path={`/privacy-policy`}>
          <Redirect to={'/en/privacy-policy'}></Redirect>
        </Route>
        <Route exact path={base} component={LandingPage} />
        <Route exact path='/'>
          <Redirect
            to={
              locales.find((lang) => lang === navLang) ? `/${navLang}` : '/en'
            }
          ></Redirect>
        </Route>
        <Route
          exact
          path={`${base}/i-postal-one`}
          component={IPostalOne}
        ></Route>
        <Route exact path={`/expo`} component={Expo}></Route>
      </Switch>
    </div>
  </Router>
);

class App extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return routing;
  }
}

export default App;
