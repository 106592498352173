import React from 'react';
import './toggleSwitch.css';

export const ToggleSwitch = (props: any) => {
  return (
    <span className='container'>
      <span className='toggle-switch'>
        <input
          type='checkbox'
          className='checkbox'
          name={props.label}
          id={props.label}
          onClick={props.onClick}
          checked={props.checked}
        />
        <label className='label' htmlFor={props.label}>
          <span className={props.rtl === 'rtl' ? 'innerRtl' : 'inner'} />
          <span className={props.rtl === 'rtl' ? 'switchRtl' : 'switch'} />
        </label>
      </span>
    </span>
  );
};
