export enum UserGenderEnum {
  NOT_SET,
  MALE,
  FEMALE,
}

export enum AvailabilityStatusEnum {
  ACTIVE,
  OFFLINE,
}

export type SignupTokenCode = {
  id: string;
  verified: boolean;
};

export type Accent = {
  id: string;
  code: string;
  title: string;
};

export type UserLanguage = {
  id: string;
  language: string;
  languageTitle: string;
  level: number;
  accent: Accent;
};

export type Industry = {
  id: string;
  title: string;
};

export type EnumItemModel = {
  id: string;
  name: string;
};

export type EnumModel = {
  id: string;
  key: string;
  name: string;
  items: Array<EnumItemModel>;
};

export type UserRating = {
  id: string;
  type: string;
  ratingId: string;
  rating: string;
  serviceLevelRating: string;
  languageLevelRating: string;
  customerSatisfactionRating: string;
  ratingCount: number;
};

export type Money = {
  amount: number;
  currency: string;
  formatted: string;
  symbol: string;
};

export type UserLocale = {
  locale: string;
  country: string;
  timezoneId: string;
  timezoneOffset: number;
};

export type Media = {
  website: string;
  whatsapp: string;
  facebook: string;
  instagram: string;
  email: string;
  phoneNumber: string;
  youtube: string;
  twitter: string;
  linkedIn: string;
  tiktok: string;
};

export type Address = {
  addressCountry: string;
  addressLocality: string;
  postalCode: string;
  streetAddress: string;
  streetNumber: string;
};

export type CallAgentLanguage = {
  name: string;
  accent: string;
};

export type CallAgentOnboardingData = {
  id: string;
  telephone: string;
  givenName: string;
  email: string;
  familyName: string;
  fcmCloudToken: string;
  avatar: string;
  birthCountry: string;
  description: string;
  languages: Array<UserLanguage>;
  industries: Array<Industry>;
  educationDegree: number;
  rating: UserRating;
  balance: Money;
  status: AvailabilityStatusEnum;
  userLocale: UserLocale;
  gender: number;
  birthDate: Date | null | string;
  mainWorkplace: string;
  media: Media;
  address: Address;
  jobType: EnumItemModel;
  hobbies: Array<EnumItemModel>;
  workExperience: Array<EnumItemModel>;
  profession: Array<EnumItemModel>;
  education: Array<EnumItemModel>;
  religion: EnumItemModel;
  religionLevel: EnumItemModel;
};

export type Language = {
  id: string;
  title: string;
  accents: Array<Accent>;
};

export type Certificate = {
  id: string;
  profession: EnumItemModel;
  jurisdiction: string;
  expirationDate: Date | null | string;
  file: string;
};
