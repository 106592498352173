import React, { useEffect } from 'react';
import iPostal from './iPostal.png';

export const IPostalOne = (props: any) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src =
      'https://widget.api.phone.do/widget-script/getHotline/e978411b-87fb-4e3a-9ab5-8472b4468615-id';
    script.async = true;

    document.body.appendChild(script);
  }, []);

  return (
    <div>
      <img src={iPostal} alt='dollar' height='100%' width='100%' />
    </div>
  );
};
