export const Pause = () => {
  return (
    <svg
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.2651 0C6.74762 0 0.646484 6.10113 0.646484 13.6186C0.646484 21.1361 6.74762 27.2372 14.2651 27.2372C21.7826 27.2372 27.8837 21.1361 27.8837 13.6186C27.8837 6.10113 21.7826 0 14.2651 0ZM12.9032 19.066H10.1795V8.17116H12.9032V19.066ZM18.3507 19.066H15.6269V8.17116H18.3507V19.066Z'
        fill='white'
      />
    </svg>
  );
};
