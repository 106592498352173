import ReactDOM from 'react-dom';
import './index.css';
import { StoreContext } from '../src/stores/use-agent-store';
import { AgentRootStore } from '../src/stores/agent-root-store';
import App from './App';
import React from 'react';

// const locales1:Array<string>= ['en', 'ru', 'es', 'fr', 'de', 'he']

declare global {
  var translation: any;
}
const Render = () => {
  return (
    <StoreContext.Provider value={new AgentRootStore()}>
      <App />
    </StoreContext.Provider>
  );
};
ReactDOM.render(<Render />, document.getElementById('root'));
