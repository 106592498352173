import { useCallback, useEffect, useState } from 'react';
import styles from './landing-page.module.scss';
import I18n from '../../lib/lingui/i18n';
import CookieConsent from 'react-cookie-consent';
import { useHistory } from 'react-router';
import lottie from 'lottie-web';
import { isMobile } from 'react-device-detect';
import {
  explanationVideolist,
  testimonialVideolist,
} from '../../globals/professional-information';

import section1 from '../../images/landing-page/section1.png';
import section3 from '../../images/landing-page/section3.png';
import section4 from '../../images/landing-page/section4.png';
import section5 from '../../images/landing-page/section5.png';
import section5Mobile from '../../images/landing-page/section5Mobile.png';
import section7 from '../../images/landing-page/section7.png';
import section7Mobile from '../../images/landing-page/section7 mobile.png';

import section10 from '../../images/landing-page/section10.png';
import section111 from '../../images/landing-page/section11-1.png';
import section112 from '../../images/landing-page/section11-2.png';
import section113 from '../../images/landing-page/section11-3.png';
import section14 from '../../images/landing-page/section14.png';
import section14Mobile from '../../images/landing-page/section14 mobile.png';
import section17 from '../../images/landing-page/section17.png';
import section17Mobile from '../../images/landing-page/section17 mobile.png';
import section18 from '../../images/landing-page/section18.png';
import appstore from '../../images/landing-page/app-store.png';
import googleplay from '../../images/landing-page/google-play.png';
import { AiOutlineArrowDown } from 'react-icons/ai';
import { AiOutlineArrowUp } from 'react-icons/ai';

import logo from '../../images/landing-page/logo.png';
import arrowDown from '../../images/arrow-down.svg';
import partner1 from '../../images/partners/partner1.png';
import partner2 from '../../images/partners/partner2.png';
import partner3 from '../../images/partners/partner3.png';
import partner4 from '../../images/partners/partner4.png';
import partner5 from '../../images/partners/partner5.png';
import partner6 from '../../images/partners/partner6.png';
import partner7 from '../../images/partners/partner7.png';
import partner8 from '../../images/partners/partner8.png';
import partner9 from '../../images/partners/partner9.png';
import partner10 from '../../images/partners/partner10.png';
import partner11 from '../../images/partners/partner11.png';

import playOrange from '../../images/landing-page/play_orange.png';
import Play from '../../images/landing-page/Play.png';
import face from '../../images/landing-page/face.png';
import questions from '../../images/landing-page/questions.png';
import plus from '../../images/landing-page/plus.png';
import minus from '../../images/landing-page/minus.png';
import whatsApp from '../../images/landing-page/whatsapp-icon.png';
import { LanguageMenu } from '../../lib/final-form/languageMenu';
import { ToggleSwitch } from '../../lib/final-form/toggle-switch';
import { Divider } from '@material-ui/core';

import newLogo from '../../images/landing-page/new-logo.png';
import customer1 from '../../images/customers/customer1.png';
import customer2 from '../../images/customers/customer2.png';
import customer3 from '../../images/customers/customer3.png';
import customer4 from '../../images/customers/customer4.png';
import customer5 from '../../images/customers/customer5.png';
import customer6 from '../../images/customers/customer6.png';
import customer7 from '../../images/customers/customer7.png';
import customer8 from '../../images/customers/customer8.png';
import customer9 from '../../images/customers/customer9.png';
import customer10 from '../../images/customers/customer10.png';
import customer11 from '../../images/customers/customer11.png';
import customer12 from '../../images/customers/customer12.png';
import customer13 from '../../images/customers/customer13.png';
import customer14 from '../../images/customers/customer14.png';
import customer15 from '../../images/customers/customer15.png';
import customer16 from '../../images/customers/customer16.png';

import agent1 from '../../images/agents/agent1.png';
import agent2 from '../../images/agents/agent2.png';
import agent3 from '../../images/agents/agent3.png';

import confetti from '../../images/animations/confetti.json';
import rocket from '../../images/animations/rocket.json';
import dotsSetcion1 from '../../images/animations/dots-setcion1.json';
import sms from '../../images/animations/sms.json';
import menuIcon from '../../images/landing-page/menu.png';

import React from 'react';
import { TopMenu } from './top-menu';

export const LandingPage = (props: any) => {
  const [languageDirection, setLanguageDirection] = useState<string>();
  // const [deviceType, setDeviceType] = useState<string>();

  const [sectionsArray] = useState<Array<string>>([
    'section1',
    'section2',
    'section3',
    'section4',
    'section5',
    'section6',
    'section7',
    'section8',
    'section9',
    'section10',
    'section11',
    'section12',
    'section13',
    'section14',
    'section15',
    'section16',
    'section17',
    'section18',
  ]);
  const currentSection = React.useRef<number>(1);
  const [finalSection, setFinalSection] = useState<boolean>(false);

  const { location } = props;
  const history = useHistory();
  const [show, setShow] = useState<Array<boolean>>([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [confettiArr, setShowConfetti] = useState<Array<boolean>>([
    false,
    false,
    false,
    false,
  ]);
  const [noArr, setNoArr] = useState<Array<boolean>>([
    false,
    false,
    false,
    false,
  ]);
  const [menu, setMenu] = useState<boolean>(false);
  const [video1, setVideo1] = useState<boolean>(false);
  const [video2, setVideo2] = useState<boolean>(false);

  const callbackObserve = useCallback((entries: any, observer: any) => {
    entries.forEach((entry: any) => {
      if (entry.target) {
        if (entry.isIntersecting) {
          const currentSectionId = Number(entry.target.id.match(/\d+/));
          if (currentSectionId !== currentSection.current) {
            currentSection.current = currentSectionId;
          }
          if (currentSection.current === 18 && finalSection === false) {
            setFinalSection(true);
          } else if (currentSection.current !== 18) {
            setFinalSection(false);
          }
        }
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const sectionInViewport = (observer: IntersectionObserver) => {
    [
      'section1',
      'section2',
      'section3',
      'section4',
      'section5',
      'section6',
      'section7',
      'section8',
      'section9',
      'section10',
      'section11',
      'section12',
      'section13',
      'section14',
      'section15',
      'section16',
      'section17',
      'section18',
    ].forEach((section) => {
      const divSction = document.getElementById(section);
      if (divSction) {
        observer.observe(divSction);
      }
    });
  };
  const rows = [
    'Are you a schmoozer? Get paid for what you do for free, for every minute of conversation in any language you speak!',
    'Want to keep your job but looking for a side gig? Need to make some extra cash for that new scooter? Get doing, get earning, get paid fast!',
    'Always dreamed of being your own boss? Setting your own hours? Working from your bed? Do it all - whenever it suits you!',
    'Get rewarded for the things you love doing and are great at Get a fat salary bonuses and as much work as you can handle Scale up or down at any time',
  ];

  useEffect(() => {
    // let typeOfDevice = navigator.userAgent.toLocaleLowerCase();
    // if (/window/i.test(typeOfDevice)) {
    //     setisMobile('desktop')
    // }
    // else if (/android/i.test(typeOfDevice)) {
    //     setisMobile('android')
    // }
    // else {
    //     setisMobile('ios'); console.log(isMobile);

    // }
    if (
      window.location.pathname.includes('he') ||
      window.location.pathname.includes('ar')
    ) {
      setLanguageDirection('rtl');
    } else {
      setLanguageDirection('ltr');
    }
    const observer = new IntersectionObserver(callbackObserve, {
      threshold: [0.7],
    });
    sectionInViewport(observer);
    const unobserve = (arr: Array<string>, obs: any) => {
      arr.forEach((item) => {
        const divItem = document.getElementById(item);
        if (divItem) {
          obs.unobserve(divItem);
        }
      });
      obs.disconnect();
    };

    window.addEventListener('beforeunload', function () {
      unobserve(sectionsArray, observer);
    });
  }, [sectionsArray, callbackObserve]);

  React.useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector('#rocket') as HTMLElement,
      animationData: rocket,
    });
    lottie.loadAnimation({
      container: document.querySelector(`#confetti${0}`) as HTMLElement,
      animationData: confetti,
    });
    lottie.loadAnimation({
      container: document.querySelector(`#confetti${1}`) as HTMLElement,
      animationData: confetti,
    });
    lottie.loadAnimation({
      container: document.querySelector(`#confetti${2}`) as HTMLElement,
      animationData: confetti,
    });
    lottie.loadAnimation({
      container: document.querySelector(`#confetti${3}`) as HTMLElement,
      animationData: confetti,
    });
    lottie.loadAnimation({
      container: document.querySelector('#dotsSetcion1') as HTMLElement,
      animationData: dotsSetcion1,
    });
    lottie.loadAnimation({
      container: document.querySelector('#sms') as HTMLElement,
      animationData: sms,
    });
  }, [menu]);
  const dropNextPage = useCallback((indexOfSection?: number) => {
    let toSection;

    if (indexOfSection !== null && indexOfSection !== undefined) {
      toSection = 'section'.concat((indexOfSection + 1).toString());
      currentSection.current = indexOfSection + 1;
      setFinalSection(false);
    } else {
      currentSection.current = currentSection.current + 1;
      toSection = 'section'.concat(Number(currentSection.current).toString());
    }

    document
      .getElementById(toSection)
      ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, []);
  const showHidden = (num: number) => {
    const array = show.map((n) => {
      return n;
    });
    array[num] = !show[num];
    setShow(array);
  };

  const displayConfetti = (num: number) => {
    const array = confettiArr.map((n) => {
      return n;
    });
    array[num] = !confettiArr[num];
    setShowConfetti(array);
  };

  const chooseNo = (num: number) => {
    const array = noArr.map((n) => {
      return n;
    });
    array[num] = !noArr[num];
    setNoArr(array);
  };
  const closeMenu = () => {
    if (
      window.location.pathname.includes('he') ||
      window.location.pathname.includes('ar')
    ) {
      setLanguageDirection('rtl');
    } else {
      setLanguageDirection('ltr');
    }
    setMenu(false);
  };
  return (
    <div>
      <CookieConsent
        location='bottom'
        buttonText={I18n.getTranslation(location, 'Accept')}
        cookieName='phone.do_cookies'
        style={{
          background: '#2B373B',
          direction: languageDirection === 'rtl' ? 'rtl' : 'ltr',
        }}
        buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
        expires={150}
        overlay={true}
        overlayStyle={{ zIndex: '300000' }}
      >
        <span>
          <I18n t='We use cookies and other tracking technologies to improve your browsing experience on our website, to show you personalized content and targeted ads, to analyze our website traffic, and to understand where our visitors are coming from' />{' '}
        </span>
        <span
          onClick={() => {
            history.push(
              `/${window.location.pathname.slice(1, 3)}/privacy-policy`,
            );
          }}
        >
          <u>
            <I18n t='Privacy Policy' /> <I18n t='Click here' />
          </u>
        </span>
      </CookieConsent>
      {menu ? (
        <TopMenu close={closeMenu} />
      ) : (
        <div>
          <div
            id='topbar'
            className={
              languageDirection === 'rtl'
                ? styles.topbar.concat(' ').concat(styles.topbarRtl)
                : styles.topbar
            }
          >
            <div
              style={{ position: 'relative' }}
              className={styles.flexBetween}
            >
              <div
                style={{ position: 'relative' }}
                className={`${styles.logoRow}`}
              >
                <img src={newLogo} alt='newLogo' className={styles.logo2} />
                <a className={`${styles.topbarItem}`} href={`http://phone.do`}>
                  <span className={styles.topbarItemText}>
                    <I18n t='Brands' />
                  </span>
                  <img src={arrowDown} alt='arrowDown'></img>
                </a>
                <a
                  className={`${styles.topbarItem}`}
                  href={`./${window.location.pathname.slice(1, 3)}`}
                >
                  <span className={styles.topbarItemText}>
                    <I18n t='Agents' />
                  </span>
                  <img src={arrowDown} alt='arrowDown'></img>
                </a>
                <a
                  className={`${styles.topbarItem}`}
                  href={`http://wp.phone.do/?page_id=3562`}
                >
                  <span className={styles.topbarItemText}>
                    <I18n t="Let's talk" />
                  </span>
                </a>
              </div>
              <div style={{ position: 'relative' }}>
                {isMobile === false ? (
                  <LanguageMenu
                    right={languageDirection === 'rtl' ? true : false}
                    setLanguageDirection={setLanguageDirection}
                  ></LanguageMenu>
                ) : (
                  <div
                    className={styles.menuIcon}
                    onClick={() => {
                      setMenu(true);
                    }}
                  >
                    <img alt='menu' src={menuIcon} />
                  </div>
                )}
                <button
                  onClick={() => {
                    history.push(
                      `/${window.location.pathname.slice(1, 3)}/registration/verify-phone/`,
                    );
                  }}
                  style={{ position: 'relative' }}
                  className={`${styles.topbarRegisterButton} ${styles.langAndBtn}`}
                >
                  <span className={styles.header}>
                    <I18n t='Register' />
                  </span>
                </button>
              </div>
            </div>
          </div>
          {/* <div id="topbar" className={languageDirection === "rtl" ? styles.topbar.concat(" ").concat(styles.topbarRtl) : styles.topbar}>
                    <div style={{ 'position': 'relative' }} className={styles.flexBetween}>
                        <div style={{ 'position': 'relative' }} className={styles.logo}><img src={logo} alt="logo" className={styles.logo1} /></div>
                        <div style={{ 'position': 'relative' }} >
                            {isMobile === false ? <LanguageMenu right={languageDirection === "rtl" ? true : false} setLanguageDirection={setLanguageDirection}></LanguageMenu> : <div className={styles.menuIcon} onClick={() => { setMenu(true) }}><img alt="menu" src={menuIcon} /></div>}
                            <button onClick={() => { history.push(`/${window.location.pathname.slice(1, 3)}/registration/verify-phone/`) }} style={{ 'position': 'relative' }} className={`${styles.topbarRegisterButton} ${styles.langAndBtn}`}><span className={styles.header}><I18n t="Register" /></span></button>
                        </div>
                    </div>
                </div> */}
          <div className={styles.landingPagebody}>
            <div id='section1' className={styles.section1}>
              <div
                className={
                  languageDirection === 'rtl'
                    ? styles.rtlLanguage.concat(' ').concat(styles.subsection)
                    : styles.subsection
                }
              >
                <div
                  className={
                    languageDirection === 'rtl'
                      ? styles.rtlLanguage.concat(' ').concat(styles.rapperRtl)
                      : styles.rapper
                  }
                >
                  <div className={styles.header}>
                    <I18n t='Become a True Free Agent' />
                  </div>
                  <div className={styles.subheader}>
                    <I18n t='You set the hours, you set the rate - get paid at any time!' />
                  </div>
                  <button
                    onClick={() => {
                      history.push(
                        `/${window.location.pathname.slice(1, 3)}/registration/verify-phone/`,
                      );
                    }}
                    className={
                      languageDirection === 'rtl'
                        ? styles.smallGreenButton
                            .concat(' ')
                            .concat(styles.smallGreenButtonRtl)
                        : styles.smallGreenButton
                    }
                  >
                    <I18n t="Let's Do it!" />
                  </button>
                  {isMobile === false ? (
                    <button
                      style={{ display: 'none' }}
                      onClick={() => {
                        dropNextPage(8);
                      }}
                      className={
                        languageDirection === 'rtl'
                          ? styles.smallGreenButton
                              .concat(' ')
                              .concat(styles.smallGreenButtonRtl)
                          : styles.smallGreenButton
                      }
                    >
                      <I18n t='Watch our video' />
                    </button>
                  ) : (
                    <div
                      onClick={() => {
                        dropNextPage(8);
                      }}
                      className={styles.watchVideoBtn}
                    >
                      <I18n t='Watch our video' />
                      <div className={styles.playOrangeBtn}>
                        <img
                          alt='playOrange1'
                          src={playOrange}
                          style={{ display: 'block', margin: 'auto' }}
                        />
                      </div>
                    </div>
                  )}
                  <div
                    id='dotsSetcion1'
                    style={
                      languageDirection === 'rtl'
                        ? { transform: 'scaleX(-1)' }
                        : {}
                    }
                    className={`${styles.confetti} ${styles.dotsSetcion1}`}
                  ></div>
                </div>
                <div>
                  <img className={styles.image} alt='section1' src={section1} />
                  <div
                    id='sms'
                    className={
                      languageDirection === 'rtl' ? styles.smsRtl : styles.sms
                    }
                  ></div>
                </div>
              </div>
            </div>
            <div id='section2' className={styles.section2}>
              <span className={styles.title}>
                <I18n t='Our partners'></I18n>
              </span>
              <div className={styles.cardsLine}>
                {[
                  partner1,
                  partner2,
                  partner3,
                  partner4,
                  partner5,
                  partner6,
                  partner7,
                  partner8,
                  partner9,
                  partner10,
                  partner11,
                ].map((item, index) => (
                  <div className={styles.card}>
                    <img
                      className={styles.img}
                      src={item}
                      alt={'partner' + item}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div id='section3' className={styles.section3}>
              <div
                className={
                  languageDirection === 'rtl'
                    ? styles.rtlLanguage.concat(' ').concat(styles.subsection)
                    : styles.subsection
                }
              >
                <div
                  className={
                    languageDirection === 'rtl'
                      ? styles.rtlLanguage.concat(' ').concat(styles.rapperRtl)
                      : styles.rapper
                  }
                >
                  <div className={styles.mediumHeader}>
                    <I18n t='Phone do a New Way of Doing Business' />
                  </div>
                  {isMobile === false ? (
                    <></>
                  ) : (
                    <img
                      className={`${languageDirection === 'rtl' ? styles.imgRtl : ''} ${
                        styles.imgSection3
                      }`}
                      alt='section3'
                      src={section3}
                    />
                  )}
                  <div className={styles.mediumSubheader}>
                    <I18n t='Phone do connects business calls and call agents around the world  think of it as a decentralized call center  where our agents are vetted trained and can work from anywhere at any time for maximum flexibility and efficiency' />
                  </div>
                  <button
                    onClick={() => {
                      history.push(
                        `/${window.location.pathname.slice(1, 3)}/registration/verify-phone/`,
                      );
                    }}
                    className={
                      languageDirection === 'rtl'
                        ? styles.smallGreenButton
                            .concat(' ')
                            .concat(styles.smallGreenButtonRtl)
                        : styles.smallGreenButton
                    }
                  >
                    <I18n t="Let's Do it!" />
                  </button>
                </div>
                {isMobile === false ? (
                  <img
                    className={`${languageDirection === 'rtl' ? styles.imgRtl : ''} ${
                      styles.imgSection3
                    }`}
                    alt='section3'
                    src={section3}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div id='section4' className={styles.section4}>
              <div
                className={
                  languageDirection === 'rtl'
                    ? styles.rtlLanguage
                        .concat(' ')
                        .concat(styles.subsection)
                        .concat(' ')
                        .concat(styles.subsectionRtl)
                    : styles.subsection
                }
              >
                {isMobile === false ? (
                  <img
                    className={`${languageDirection === 'rtl' ? styles.imgRtl : ''} ${
                      styles.imgSection3
                    }`}
                    alt='section4'
                    src={section4}
                  />
                ) : (
                  <></>
                )}
                <div
                  className={
                    languageDirection === 'rtl'
                      ? styles.rapperRtl
                      : styles.rapper
                  }
                >
                  <div className={styles.mediumHeader}>
                    <I18n t='Create your own Dream Job!' />
                  </div>
                  {isMobile === false ? (
                    <></>
                  ) : (
                    <img
                      className={`${languageDirection === 'rtl' ? styles.imgRtl : ''} ${
                        styles.imgSection3
                      } ${styles.imgSection3Mobile}`}
                      alt='section4'
                      src={section4}
                    />
                  )}
                  <div className={styles.mediumSubheader}>
                    <I18n t="We're looking for driven fast learners who want maximum flexibility and maximum earning power The salaries are great and the hours even greater! And best of all get paid as you go No more waiting a month" />
                  </div>
                  <button
                    onClick={() => {
                      history.push(
                        `/${window.location.pathname.slice(1, 3)}/registration/verify-phone/`,
                      );
                    }}
                    className={
                      languageDirection === 'rtl'
                        ? styles.smallGreenButton
                            .concat(' ')
                            .concat(styles.smallGreenButtonRtl)
                        : styles.smallGreenButton
                    }
                  >
                    <I18n t="Let's Do it!" />
                  </button>
                </div>
              </div>
            </div>
            <div id='section5' className={styles.section5}>
              <div
                className={
                  languageDirection === 'rtl'
                    ? styles.rtlLanguage
                        .concat(' ')
                        .concat(styles.subsection)
                        .concat(' ')
                        .concat(styles.subsectionRtl)
                    : styles.subsection
                }
              >
                <div
                  className={
                    languageDirection === 'rtl'
                      ? styles.rapperRtl
                      : styles.rapper
                  }
                >
                  <div className={styles.mediumHeader}>
                    <I18n t='Do What you Love - Love What you Do' />
                  </div>
                  {isMobile === false ? (
                    <></>
                  ) : (
                    <img
                      className={`${languageDirection === 'rtl' ? styles.imgRtl : ''} ${
                        styles.imgSection3
                      } ${styles.imgSection5mobile}`}
                      alt='section5'
                      src={section5Mobile}
                    />
                  )}
                  <div className={styles.mediumSubheader}>
                    <I18n t=" If you're a real people person, know how to listen and are a problem solver, we want you! Turn your passion into profit, and earn money as you build your dreams - with a fun, flexible and lucrative job" />
                  </div>
                  <button
                    onClick={() => {
                      history.push(
                        `/${window.location.pathname.slice(1, 3)}/registration/verify-phone/`,
                      );
                    }}
                    className={
                      languageDirection === 'rtl'
                        ? styles.smallGreenButton
                            .concat(' ')
                            .concat(styles.smallGreenButtonRtl)
                        : styles.smallGreenButton
                    }
                  >
                    <I18n t="Let's Do it!" />
                  </button>
                </div>
                {isMobile === false ? (
                  <img
                    className={`${languageDirection === 'rtl' ? styles.imgRtl : ''} ${
                      styles.imgSection3
                    } ${styles.imgSection5}`}
                    alt='section5'
                    src={section5}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div
              id='section6'
              className={`${styles.section6} ${
                languageDirection === 'rtl' ? styles.rtlLanguage : ''
              }`}
            >
              <div className={styles.rapper}>
                <div
                  style={
                    (window.location.pathname.includes('fr') ||
                      window.location.pathname.includes('gr')) &&
                    isMobile
                      ? { fontSize: '47px' }
                      : {}
                  }
                  className={`${
                    languageDirection === 'rtl'
                      ? styles.text1.concat(' ').concat(styles.textRtl)
                      : styles.text1
                  }`}
                >
                  <I18n t='Why use'></I18n>
                </div>
                <div
                  className={
                    languageDirection === 'rtl'
                      ? styles.text2.concat(' ').concat(styles.textRtl)
                      : styles.text2
                  }
                >
                  <span>Phone.do?</span>
                </div>
                <div className={styles.text3}>
                  <span>Phone.do</span>
                </div>
                <div className={styles.text3}>
                  <span>
                    <I18n t='The Future of Doing is Here'></I18n>
                  </span>
                </div>
                <div className={styles.text4}>
                  <span>
                    <I18n t="We believe businesses should have great coverage at all times - so they can rest assured that the next lead, the next customer and the next query are captured and taken care of We also believe that great work can happen anywhere It's a win win - and we want you to be part of the future"></I18n>
                  </span>
                </div>
                <button
                  onClick={() => {
                    history.push(
                      `/${window.location.pathname.slice(1, 3)}/registration/verify-phone/`,
                    );
                  }}
                  className={
                    languageDirection === 'rtl'
                      ? styles.smallGreenButton
                          .concat(' ')
                          .concat(styles.smallGreenButtonRtl)
                      : styles.smallGreenButton
                  }
                >
                  <I18n t='I want to join too' />
                </button>
              </div>
            </div>
            <div id='section7' className={styles.section7}>
              <div
                className={
                  languageDirection === 'rtl' ? styles.rtlLanguage : ''
                }
              >
                <div className={styles.mediumHeader}>
                  <span>
                    <I18n t='Come and be a part of the global Phonedo community'></I18n>
                  </span>
                </div>
              </div>
            </div>
            <div id='section8' className={styles.section8}>
              {isMobile === false ? (
                <img alt='section7' className={styles.image} src={section7} />
              ) : (
                <img
                  alt='section7'
                  className={styles.image}
                  src={section7Mobile}
                />
              )}
            </div>
            <div id='section9' className={styles.section9}>
              <div
                className={
                  languageDirection === 'rtl' ? styles.rtlLanguage : ''
                }
              >
                {isMobile === false ? (
                  <div className={styles.flex}>
                    {video1 ? (
                      <div
                        style={{ marginInlineEnd: '1px' }}
                        className={styles.desktopVideo}
                        onMouseLeave={() => {
                          setVideo1(false);
                        }}
                      >
                        <video
                          autoPlay
                          loop
                          muted
                          id='video1'
                          style={
                            video1
                              ? { display: 'unset', width: '100%' }
                              : { display: 'none' }
                          }
                        >
                          <source
                            src={`${process.env.S3_BUCKET}/landing-page/agent/www.Phone.do%20Agent%20Case%20Study.mp4`}
                            type={'video/mp4'}
                          />
                        </video>
                        <img
                          alt='playM1'
                          src={Play}
                          className={
                            languageDirection === 'rtl'
                              ? styles.playImg2
                              : styles.playImg
                          }
                          onClick={() =>
                            window.open(
                              explanationVideolist[
                                window.location.pathname.slice(1, 3) as any
                              ],
                            )
                          }
                        />
                      </div>
                    ) : (
                      <div
                        style={{ marginInlineEnd: '1px' }}
                        className={styles.video}
                        onMouseOver={() => {
                          setVideo1(true);
                          setVideo2(false);
                        }}
                      >
                        <div
                          style={video1 ? { display: 'none' } : {}}
                          className={styles.videoCard}
                        >
                          <div className={styles.title}>
                            <span>
                              <I18n t='How we do what we do'></I18n>
                            </span>
                          </div>
                          <div className={styles.subtitle}>
                            <span>
                              <I18n t='Welcome to Phone do  a fully decentralized Business Agent platform that hires and trains freelancers from all over the world to ensure the perfect rep is responding to each and every call Connecting businesses to their customers and prospects at any time for the perfect experience'></I18n>
                            </span>
                          </div>
                          <button
                            onClick={() =>
                              window.open(
                                explanationVideolist[
                                  window.location.pathname.slice(1, 3) as any
                                ],
                              )
                            }
                            className={styles.playButton}
                          >
                            <I18n t='Watch our video' />
                            <img
                              style={{ marginLeft: '18px' }}
                              alt='playOrange'
                              src={playOrange}
                            />
                          </button>
                        </div>
                      </div>
                    )}
                    {video2 ? (
                      <div
                        className={styles.desktopVideo}
                        onMouseLeave={() => {
                          setVideo2(false);
                        }}
                      >
                        <video
                          autoPlay
                          loop
                          muted
                          id='video2'
                          style={
                            video2
                              ? { display: 'unset', width: '100%' }
                              : { display: 'none' }
                          }
                        >
                          <source
                            src={`${process.env.S3_BUCKET}/landing-page/agent/www.Phone.do%20Agent%20Case%20Study_1.mp4`}
                            type={'video/mp4'}
                          />
                        </video>
                        <img
                          alt='playM1'
                          src={Play}
                          className={
                            languageDirection === 'rtl'
                              ? styles.playImg
                              : styles.playImg2
                          }
                          onClick={() =>
                            window.open(
                              testimonialVideolist[
                                window.location.pathname.slice(1, 3) as any
                              ],
                            )
                          }
                        />
                      </div>
                    ) : (
                      <div
                        className={styles.video}
                        onMouseOver={() => {
                          setVideo1(false);
                          setVideo2(true);
                        }}
                      >
                        <div
                          style={video2 ? { display: 'none' } : {}}
                          className={styles.videoCard}
                        >
                          <div className={styles.title}>
                            <span>
                              <I18n t='Our Agents love us they really do!'></I18n>
                            </span>
                          </div>
                          <div className={styles.subtitle}>
                            <span>
                              <I18n t='Dont take it from us - hear what our agents have to say'></I18n>
                            </span>
                          </div>
                          <button
                            onClick={() =>
                              window.open(
                                testimonialVideolist[
                                  window.location.pathname.slice(1, 3) as any
                                ],
                              )
                            }
                            className={styles.playButton}
                          >
                            <I18n t='Watch our video' />
                            <img
                              style={{ marginLeft: '18px', marginTop: '2px' }}
                              alt='playOrange'
                              src={playOrange}
                            />
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                    <div>
                      <div
                        style={video1 ? { background: 'none' } : {}}
                        className={styles.mobileVideo}
                        onTouchMove={() => {
                          setVideo1(true);
                          setVideo2(false);
                        }}
                      >
                        <video
                          autoPlay
                          loop
                          muted
                          id='video2'
                          style={
                            video1
                              ? { display: 'unset', width: '100%' }
                              : { display: 'none' }
                          }
                        >
                          <source
                            src={`${process.env.S3_BUCKET}/landing-page/agent/www.Phone.do%20Agent%20Case%20Study.mp4`}
                            type={'video/mp4'}
                          />
                        </video>
                        <img
                          alt='playM1'
                          src={Play}
                          className={styles.playImg}
                          onClick={() =>
                            window.open(
                              explanationVideolist[
                                window.location.pathname.slice(1, 3) as any
                              ],
                            )
                          }
                        />
                      </div>
                      <div className={styles.titleMobile}>
                        <span>
                          <I18n t='How we do what we do'></I18n>
                        </span>
                      </div>
                      <div className={styles.subtitleMobile}>
                        <span>
                          <I18n t='Welcome to Phone do  a fully decentralized Business Agent platform that hires and trains freelancers from all over the world to ensure the perfect rep is responding to each and every call Connecting businesses to their customers and prospects at any time for the perfect experience'></I18n>
                        </span>
                      </div>
                    </div>
                    <div>
                      <div
                        style={video2 ? { background: 'none' } : {}}
                        className={styles.mobileVideo}
                        onTouchMove={() => {
                          setVideo1(false);
                          setVideo2(true);
                        }}
                      >
                        <video
                          autoPlay
                          loop
                          muted
                          id='video2'
                          style={
                            video2
                              ? { display: 'unset', width: '100%' }
                              : { display: 'none' }
                          }
                        >
                          <source
                            src={`${process.env.S3_BUCKET}/landing-page/agent/www.Phone.do%20Agent%20Case%20Study_1.mp4`}
                            type={'video/mp4'}
                          />
                        </video>
                        <img
                          alt='playM2'
                          src={Play}
                          className={styles.playImg}
                          onClick={() =>
                            window.open(
                              testimonialVideolist[
                                window.location.pathname.slice(1, 3) as any
                              ],
                            )
                          }
                        />
                      </div>
                      <div
                        className={`${styles.titleMobile} ${styles.titleMobile2}`}
                      >
                        <span>
                          <I18n t='Our Agents love us they really do!'></I18n>
                        </span>
                      </div>
                      <div className={styles.subtitleMobile}>
                        <span>
                          <I18n t='Dont take it from us - hear what our agents have to say'></I18n>
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div id='section10' className={styles.section10}>
              <div
                className={
                  languageDirection === 'rtl'
                    ? styles.rtlLanguage.concat(' ').concat(styles.subsection)
                    : styles.subsection
                }
              >
                <div
                  className={
                    languageDirection === 'rtl'
                      ? styles.rtlLanguage.concat(' ').concat(styles.rapperRtl)
                      : styles.rapper
                  }
                >
                  <div className={styles.mediumHeader}>
                    <I18n t='How do you do it?' />
                  </div>
                  {isMobile === false ? (
                    <></>
                  ) : (
                    <img
                      className={`${languageDirection === 'rtl' ? styles.imgRtl : ''} ${
                        styles.imgSection3
                      }`}
                      alt='section10'
                      src={section10}
                    />
                  )}
                  <div className={styles.mediumSubheader}>
                    <I18n t='Build your freelance business easily, using your passions and talents to make money from anywhere! You can be sitting at home, on a beach in the Caribbeans or on the top of the Himalayas - as long as you have a good connection and a strong commitment - we want you!' />
                  </div>
                  <button
                    onClick={() => {
                      history.push(
                        `/${window.location.pathname.slice(1, 3)}/registration/verify-phone/`,
                      );
                    }}
                    className={
                      languageDirection === 'rtl'
                        ? styles.smallGreenButton
                            .concat(' ')
                            .concat(styles.smallGreenButtonRtl)
                        : styles.smallGreenButton
                    }
                  >
                    <I18n t="Let's Do it!" />
                  </button>
                </div>
                {isMobile === false ? (
                  <img
                    className={`${languageDirection === 'rtl' ? styles.imgRtl : ''} ${
                      styles.imgSection3
                    }`}
                    alt='section10'
                    src={section10}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div id='section11' className={styles.section11}>
              <div
                className={
                  languageDirection === 'rtl'
                    ? styles.rtlLanguage.concat(' ').concat(styles.rapperRtl)
                    : styles.rapper
                }
              >
                <div className={styles.smallsubsection1}>
                  <div className={styles.mediumHeader}>
                    <I18n t='Build your freelance business easily with only three steps?' />
                  </div>
                </div>
                <div className={styles.smallsubsection}>
                  <img
                    alt='section11'
                    className={styles.image}
                    src={section111}
                  />
                  <div
                    className={
                      languageDirection === 'rtl'
                        ? styles.smallrapperRtl
                        : styles.smallrapper
                    }
                  >
                    <div className={styles.smallHeader}>
                      <I18n t='1  Register to our platform' />
                    </div>
                    <div className={styles.mediumSubheader}>
                      <I18n t='We want to get to know you so we can match you with the right opportunities: Name, languages, skills, experience, expertise, hobbies, favorite food' />
                    </div>
                  </div>
                </div>
                <Divider className={styles.divider} />
                <div className={styles.smallsubsection}>
                  <img
                    alt='section112'
                    className={styles.image}
                    src={section112}
                  />
                  <div
                    className={
                      languageDirection === 'rtl'
                        ? styles.smallrapperRtl
                        : styles.smallrapper
                    }
                  >
                    <div className={styles.smallHeader}>
                      <I18n t='2  Setup your Profile' />
                    </div>
                    <div className={styles.mediumSubheader}>
                      <I18n t='Welcome to the Phone do family! Now create your profile based on the activity types you love most  i e Sales calls Customer Support Customer Service Enrollment Tech Support  Activation Fundraising Surveys and more The more activites you add  the more opportunities we send your way!' />
                    </div>
                  </div>
                </div>
                <Divider className={styles.divider} />
                <div className={styles.smallsubsection}>
                  <img
                    alt='section113'
                    className={styles.image}
                    src={section113}
                  />
                  <div
                    className={
                      languageDirection === 'rtl'
                        ? styles.smallrapperRtl
                        : styles.smallrapper
                    }
                  >
                    <div className={styles.smallHeader}>
                      <I18n t='3  Activate' />
                    </div>
                    <div className={styles.mediumSubheader}>
                      <I18n t="Happy Great  now activate Set your schedule same for every day or crazy hours  you're the doer You can pause or increase hours at any time" />
                    </div>
                  </div>
                </div>
                {isMobile && <Divider className={styles.lastDivider} />}
              </div>
            </div>
            <div id='section12' className={styles.section12}>
              <div
                className={
                  languageDirection === 'rtl'
                    ? styles.rtlLanguage.concat(' ').concat(styles.subsection)
                    : styles.subsection
                }
              >
                <div style={{ textAlign: 'center', marginTop: '20vh' }}>
                  <div className={styles.mediumHeader}>
                    <I18n t='Our Customers' />
                  </div>
                  <div className={styles.mediumSubheader}>
                    <I18n t='Shops and services at:' />
                  </div>
                </div>
              </div>
              <div className={styles.subSectionCard}>
                <div
                  className={
                    languageDirection === 'rtl'
                      ? styles.rtlLanguage.concat(' ').concat(styles.subsection)
                      : styles.subsection
                  }
                >
                  <div className={styles.card}>
                    <div className={styles.image}>
                      <img alt='customer' src={customer1} />
                    </div>
                    <div className={styles.verysmallHeader}>
                      Magento Commerce
                    </div>
                  </div>
                  <div className={styles.card}>
                    <div className={styles.image}>
                      <img alt='customer' src={customer2} />
                    </div>
                    <div className={styles.verysmallHeader}>3dcart</div>
                  </div>
                  <div className={styles.card}>
                    <div className={styles.image}>
                      <img alt='customer' src={customer3} />
                    </div>
                    <div className={styles.verysmallHeader}>Commerce Cloud</div>
                  </div>
                  <div className={styles.card}>
                    <div className={styles.image}>
                      <img alt='customer' src={customer4} />
                    </div>
                    <div className={styles.verysmallHeader}>volusion</div>
                  </div>
                  <div className={styles.card}>
                    <div className={styles.image}>
                      <img alt='customer' src={customer5} />
                    </div>
                    <div className={styles.verysmallHeader}>Elementor</div>
                  </div>
                  <div className={styles.card}>
                    <div className={styles.image}>
                      <img alt='customer' src={customer6} />
                    </div>
                    <div className={styles.verysmallHeader}>Quick eSelling</div>
                  </div>
                  <div className={styles.card}>
                    <div className={styles.image}>
                      <img alt='customer' src={customer7} />
                    </div>
                    <div className={styles.verysmallHeader}>My Dukaan</div>
                  </div>
                  <div className={styles.card}>
                    <div className={styles.image}>
                      <img alt='customer' src={customer8} />
                    </div>
                    <div className={styles.verysmallHeader}>GoDaddy</div>
                  </div>
                </div>
                <div
                  className={
                    languageDirection === 'rtl'
                      ? styles.rtlLanguage.concat(' ').concat(styles.subsection)
                      : styles.subsection
                  }
                >
                  <div className={styles.card}>
                    <div className={styles.image}>
                      <img alt='customer' src={customer9} />
                    </div>
                    <div className={styles.verysmallHeader}>Shopify</div>
                  </div>
                  <div className={styles.card}>
                    <div className={styles.image}>
                      <img alt='customer' src={customer10} />
                    </div>
                    <div className={styles.verysmallHeader}>Magento</div>
                  </div>
                  <div className={styles.card}>
                    <div className={styles.image}>
                      <img alt='customer' src={customer11} />
                    </div>
                    <div className={styles.verysmallHeader}>Squarespace</div>
                  </div>
                  <div className={styles.card}>
                    <div className={styles.image}>
                      <img alt='customer' src={customer12} />
                    </div>
                    <div className={styles.verysmallHeader}>WIX</div>
                  </div>
                  <div className={styles.card}>
                    <div className={styles.image}>
                      <img alt='customer' src={customer13} />
                    </div>
                    <div className={styles.verysmallHeader}>yo!kart</div>
                  </div>
                  <div className={styles.card}>
                    <div className={styles.image}>
                      <img alt='customer' src={customer14} />
                    </div>
                    <div className={styles.verysmallHeader}>
                      Template Monster
                    </div>
                  </div>
                  <div
                    style={
                      languageDirection === 'rtl'
                        ? { width: '110px', paddingRight: '10px' }
                        : { width: '110px', paddingLeft: '10px' }
                    }
                    className={styles.card}
                  >
                    <div className={styles.image}>
                      <img alt='customer' src={customer15} />
                    </div>
                    <div className={styles.verysmallHeader}>BIGCOMMERCE</div>
                  </div>
                  <div
                    style={
                      languageDirection === 'rtl'
                        ? { width: '110px', paddingRight: '10px' }
                        : { width: '110px', paddingLeft: '10px' }
                    }
                    className={styles.card}
                  >
                    <div className={styles.image}>
                      <img alt='customer' src={customer16} />
                    </div>
                    <div className={styles.verysmallHeader}>WOO COMMERCE</div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id='section13'
              className={styles.section13}
              style={
                languageDirection === 'rtl'
                  ? { direction: 'rtl' }
                  : { direction: 'ltr' }
              }
            >
              <div className={styles.flexLine}>
                <div id='rocket' className={`${styles.animation}`}></div>
              </div>
              <div className={styles.flexLine}>
                <span className={styles.title}>
                  <I18n t='Check if you are a Phonedo agent'></I18n>
                </span>
                <img className={styles.imgFace} src={face} alt={'face'} />
              </div>
              <div className={styles.table}>
                <div className={`${styles.row} ${styles.rowTitle}`}>
                  <span style={{ position: 'relative', width: '65%' }}></span>
                  <div className={styles.rowBtn}>
                    <div
                      className={
                        languageDirection === 'rtl'
                          ? styles.smallrowBtnRtl
                          : styles.smallrowBtn
                      }
                    >
                      <span
                        style={{ position: 'relative' }}
                        className={styles.title}
                      >
                        <I18n t='Yes, I’m'></I18n>
                      </span>
                    </div>
                    <div
                      className={
                        languageDirection === 'rtl'
                          ? styles.smallrowBtnRtl
                          : styles.smallrowBtn
                      }
                    >
                      <span
                        style={{ position: 'relative' }}
                        className={styles.title}
                      >
                        <I18n t='No, I’m not'></I18n>
                      </span>
                    </div>
                  </div>
                </div>
                <Divider />
                {isMobile === false
                  ? rows.map((item, index) => (
                      <div>
                        <div className={styles.row}>
                          <span
                            style={{ position: 'relative' }}
                            className={styles.text}
                          >
                            <I18n t={item}></I18n>
                          </span>
                          <div className={styles.rowBtn}>
                            <div
                              id={`confetti${index}`}
                              style={
                                confettiArr[index]
                                  ? { display: 'block' }
                                  : { display: 'none' }
                              }
                              className={`${styles.animation} ${styles.confetti}`}
                            ></div>
                            <button
                              style={{ position: 'relative' }}
                              className={styles.greenBtn}
                            >
                              <span
                                style={{ position: 'relative' }}
                                className={styles.smallBtnText}
                              >
                                <I18n t='Yes, I’m'></I18n>
                              </span>
                              <ToggleSwitch
                                id={`yes${index}`}
                                label={`yes${index}`}
                                style={{ position: 'relative' }}
                                rtl={languageDirection}
                                onClick={
                                  noArr[index]
                                    ? null
                                    : () => displayConfetti(index)
                                }
                                checked={confettiArr[index]}
                              />
                            </button>
                            <button
                              style={{ position: 'relative' }}
                              className={styles.redBtn}
                            >
                              <span
                                style={{ position: 'relative' }}
                                className={styles.smallBtnText}
                              >
                                <I18n t='No, I’m not'></I18n>
                              </span>
                              <ToggleSwitch
                                label={`no${index}`}
                                style={{ position: 'relative' }}
                                rtl={languageDirection}
                                onClick={
                                  confettiArr[index]
                                    ? null
                                    : () => chooseNo(index)
                                }
                                checked={noArr[index]}
                              />
                            </button>
                          </div>
                        </div>
                        <Divider />
                      </div>
                    ))
                  : rows.map((item, index) => (
                      <div>
                        <div className={styles.row}>
                          <span
                            style={{ position: 'relative' }}
                            className={styles.text}
                          >
                            <I18n t={item}></I18n>
                          </span>

                          <div className={styles.rowBtn}>
                            <div
                              id={`confetti${index}`}
                              style={
                                confettiArr[index]
                                  ? { display: 'block' }
                                  : { display: 'none' }
                              }
                              className={`${styles.animation} ${styles.confetti}`}
                            ></div>
                            <div className={styles.cardbtn}>
                              <span
                                style={{ position: 'relative' }}
                                className={
                                  languageDirection === 'rtl'
                                    ? styles.smallBtnTextRtl
                                    : styles.smallBtnText
                                }
                              >
                                <I18n t='Yes, I’m'></I18n>
                              </span>
                              <button
                                style={{ position: 'relative' }}
                                className={styles.greenBtn}
                              >
                                <ToggleSwitch
                                  id={`yes${index}`}
                                  label={`yes${index}`}
                                  style={{ position: 'relative' }}
                                  rtl={languageDirection}
                                  onClick={
                                    noArr[index]
                                      ? null
                                      : () => displayConfetti(index)
                                  }
                                  checked={confettiArr[index]}
                                />
                              </button>
                            </div>
                            <div className={styles.cardbtn}>
                              <span
                                style={{ position: 'relative' }}
                                className={
                                  languageDirection === 'rtl'
                                    ? styles.smallBtnTextRtl
                                    : styles.smallBtnText
                                }
                              >
                                <I18n t='No, I’m not'></I18n>
                              </span>
                              <button
                                style={{ position: 'relative' }}
                                className={styles.redBtn}
                              >
                                <ToggleSwitch
                                  label={`no${index}`}
                                  style={{ position: 'relative' }}
                                  rtl={languageDirection}
                                  onClick={
                                    confettiArr[index]
                                      ? null
                                      : () => chooseNo(index)
                                  }
                                  checked={noArr[index]}
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        <Divider />
                      </div>
                    ))}
              </div>
            </div>
            <div id='section14' className={styles.section14}>
              <div
                style={
                  isMobile === false
                    ? { position: 'relative', top: '240px' }
                    : {}
                }
              >
                {isMobile === false ? (
                  <img
                    className={styles.image}
                    alt='section14'
                    src={section14}
                  />
                ) : (
                  <img
                    className={styles.image}
                    alt='section14'
                    src={section14Mobile}
                  />
                )}
                <div
                  className={
                    languageDirection === 'rtl'
                      ? styles.rtlLanguage.concat(' ').concat(styles.card)
                      : styles.card
                  }
                >
                  <div className={styles.header}>
                    <I18n t='Ready to win the agent war?' />
                  </div>
                  <button
                    onClick={() => {
                      history.push(
                        `/${window.location.pathname.slice(1, 3)}/registration/verify-phone/`,
                      );
                    }}
                    className={
                      languageDirection === 'rtl'
                        ? styles.smallGreenButton
                            .concat(' ')
                            .concat(styles.smallGreenButtonRtl)
                        : styles.smallGreenButton
                    }
                  >
                    <I18n t="Let's Do it!" />
                  </button>
                </div>
              </div>
            </div>
            <div
              id='section15'
              className={
                languageDirection === 'rtl'
                  ? styles.rtlLanguage.concat(' ').concat(styles.section15)
                  : styles.section15
              }
            >
              <Divider className={styles.divider} />
              <div style={{ position: 'relative', top: '50px' }}>
                <div className={styles.header}>
                  <I18n t='What Agents Say' />
                </div>
                <div
                  className={`${
                    languageDirection === 'rtl'
                      ? styles.rtlLanguage.concat(' ').concat(styles.subsection)
                      : styles.subsection
                  } ${styles.subsectionCol}`}
                >
                  <div
                    className={
                      languageDirection === 'rtl'
                        ? styles.rtlLanguage
                            .concat(' ')
                            .concat(styles.subsection)
                        : styles.subsection
                    }
                  >
                    <div className={styles.card}>
                      <div
                        style={{ justifyContent: 'flex-start' }}
                        className={styles.subsection
                          .concat(' ')
                          .concat(styles.image)}
                      >
                        <img alt='agent' src={agent1} />
                        <div
                          className={
                            languageDirection === 'rtl'
                              ? styles.titleCardRtl
                              : styles.titleCard
                          }
                        >
                          <div className={styles.mediumHeader}>
                            <I18n t='Josef Kiarn' />
                          </div>
                          <div className={styles.mediumGreyHeader}>
                            <I18n t='Valleta - Malta' />
                          </div>
                        </div>
                      </div>
                      <div className={styles.mediumSubheader}>
                        <I18n t='Rewarding work I choose which calls to answer Feels satisfaction and self-fulfillment' />
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      languageDirection === 'rtl'
                        ? styles.rtlLanguage
                            .concat(' ')
                            .concat(styles.subsection)
                        : styles.subsection
                    }
                  >
                    <div className={styles.card}>
                      <div
                        style={{ justifyContent: 'flex-start' }}
                        className={styles.subsection
                          .concat(' ')
                          .concat(styles.image)}
                      >
                        <img alt='agent' src={agent2} />
                        <div
                          className={
                            languageDirection === 'rtl'
                              ? styles.titleCardRtl
                              : styles.titleCard
                          }
                        >
                          <div className={styles.mediumHeader}>
                            <I18n t='Maria Kivotis' />
                          </div>
                          <div className={styles.mediumGreyHeader}>
                            <I18n t='Limassol - Cyprus' />
                          </div>
                        </div>
                      </div>
                      <div className={styles.mediumSubheader}>
                        <I18n t='I choose exotic places and create the work environment for me Nothing to say enjoying every moment' />
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      languageDirection === 'rtl'
                        ? styles.rtlLanguage
                            .concat(' ')
                            .concat(styles.subsection)
                        : styles.subsection
                    }
                  >
                    <div className={styles.card}>
                      <div
                        style={{ justifyContent: 'flex-start' }}
                        className={styles.subsection
                          .concat(' ')
                          .concat(styles.image)}
                      >
                        <img alt='agent' src={agent3} />
                        <div
                          className={
                            languageDirection === 'rtl'
                              ? styles.titleCardRtl
                              : styles.titleCard
                          }
                        >
                          <div className={styles.mediumHeader}>
                            <I18n t='Nancy Camerun' />
                          </div>
                          <div className={styles.mediumGreyHeader}>
                            <I18n t='Fort Lauderdale - USA' />
                          </div>
                        </div>
                      </div>
                      <div className={styles.mediumSubheader}>
                        <I18n t='I never thought I could control the height of my earnings I decide how long to work and when to withdraw the money This is definitely genius' />
                      </div>
                    </div>
                  </div>
                </div>
                <Divider
                  style={{ width: '70vw', marginInlineStart: '15vw' }}
                  className={styles.divider}
                />
                <div />
              </div>
            </div>
            <div
              id='section16'
              className={styles.section16}
              style={
                languageDirection === 'rtl'
                  ? { direction: 'rtl' }
                  : { direction: 'ltr' }
              }
            >
              <div className={styles.flexBetween}>
                <div
                  style={{ position: 'relative' }}
                  className={`${styles.side}  ${styles.side1}`}
                >
                  <div className={styles.largeTitle}>
                    <I18n t='Any questions?' />
                  </div>
                  <img
                    alt='questions'
                    src={questions}
                    className={styles.questionsImg}
                  />
                </div>
                <div
                  style={{ position: 'relative' }}
                  className={`${styles.side}`}
                >
                  <div className={styles.smallTitle}>
                    <I18n t='FAQ' />
                  </div>
                  {!show[0] ? (
                    <div className={styles.questionRow}>
                      <img
                        className={styles.plusMinusImg}
                        src={plus}
                        alt={plus}
                        onClick={() => showHidden(0)}
                      />
                      <span className={styles.mediumTitle}>
                        <I18n t='How much will I get paid?' />
                      </span>
                    </div>
                  ) : (
                    <>
                      <div className={styles.questionRow}>
                        <img
                          className={styles.plusMinusImg}
                          src={minus}
                          alt={'minus1'}
                          onClick={() => showHidden(0)}
                        />
                        <div className={styles.mediumTitle}>
                          <I18n t='How much will I get paid?' />
                        </div>
                      </div>
                      <div className={styles.answer}>
                        <I18n t="We give you a recommended rate per minute for your work to our clients based on supply and demand and the type of calls A business can set a maximum price they are willing to pay The right balance for all of us is when there are enough calls for you and it's financially viable for the company Our job is to find the right balance so that everyone wins" />
                      </div>
                    </>
                  )}
                  <div className={styles.questioDivider}>
                    <Divider />
                  </div>
                  {!show[1] ? (
                    <div className={styles.questionRow}>
                      <img
                        className={styles.plusMinusImg}
                        src={plus}
                        alt={plus}
                        onClick={() => showHidden(1)}
                      />
                      <span className={styles.mediumTitle}>
                        <I18n t='How & When do I get Paid?' />
                      </span>
                    </div>
                  ) : (
                    <>
                      <div className={styles.questionRow}>
                        <img
                          className={styles.plusMinusImg}
                          src={minus}
                          alt={'minus1'}
                          onClick={() => showHidden(1)}
                        />
                        <div className={styles.mediumTitle}>
                          <I18n t='How & When do I get Paid?' />
                        </div>
                      </div>
                      <div className={styles.answer}>
                        <I18n t='All earnings are available for immediate withdrawal in any currency via transfer to your local bank account, PayPal, Debit Card, or Check' />
                      </div>
                    </>
                  )}
                  <div className={styles.questioDivider}>
                    <Divider />
                  </div>
                  {!show[2] ? (
                    <div className={styles.questionRow}>
                      <img
                        className={styles.plusMinusImg}
                        src={plus}
                        alt={plus}
                        onClick={() => showHidden(2)}
                      />
                      <span className={styles.mediumTitle}>
                        <I18n t='What is Phonedo’s cut?' />
                      </span>
                    </div>
                  ) : (
                    <>
                      <div className={styles.questionRow}>
                        <img
                          className={styles.plusMinusImg}
                          src={minus}
                          alt={'minus1'}
                          onClick={() => showHidden(2)}
                        />
                        <div className={styles.mediumTitle}>
                          <I18n t='What is Phonedo’s cut?' />
                        </div>
                      </div>
                      <div className={styles.answer}>
                        <I18n t='Great question We take a small percentage of the fee between you and the agent This allows us to keep the platform operating effectively advertise for new clients and generally keep this awesome community running and building their business and revenues' />
                      </div>
                    </>
                  )}
                  <div className={styles.questioDivider}>
                    <Divider />
                  </div>
                  {!show[3] ? (
                    <div className={styles.questionRow}>
                      <img
                        className={styles.plusMinusImg}
                        src={plus}
                        alt={plus}
                        onClick={() => showHidden(3)}
                      />
                      <span className={styles.mediumTitle}>
                        <I18n t='Are there any costs to join?' />
                      </span>
                    </div>
                  ) : (
                    <>
                      <div className={styles.questionRow}>
                        <img
                          className={styles.plusMinusImg}
                          src={minus}
                          alt={'minus1'}
                          onClick={() => showHidden(3)}
                        />
                        <div className={styles.mediumTitle}>
                          <I18n t='Are there any costs to join?' />
                        </div>
                      </div>
                      <div className={styles.answer}>
                        <I18n t='No There is no sign-up fee and no ongoing monthly fees' />
                      </div>
                    </>
                  )}
                  <div className={styles.questioDivider}>
                    <Divider />
                  </div>
                  {!show[4] ? (
                    <div className={styles.questionRow}>
                      <img
                        className={styles.plusMinusImg}
                        src={plus}
                        alt={plus}
                        onClick={() => showHidden(4)}
                      />
                      <span className={styles.mediumTitle}>
                        <I18n t='How can I get promoted?' />
                      </span>
                    </div>
                  ) : (
                    <>
                      <div className={styles.questionRow}>
                        <img
                          className={styles.plusMinusImg}
                          src={minus}
                          alt={'minus1'}
                          onClick={() => showHidden(4)}
                        />
                        <div className={styles.mediumTitle}>
                          <I18n t='How can I get promoted?' />
                        </div>
                      </div>
                      <div className={styles.answer}>
                        <I18n t="This isn't a typical job role There are no bosses asking you to stay late or come in early You can earn great ratings through the site based on the work you carry out for your clients The higher your rating the more you can earn for each job Just like any online community your reputation earns you more demand" />
                      </div>
                    </>
                  )}
                  <div className={styles.questioDivider}>
                    <Divider />
                  </div>
                  {!show[5] ? (
                    <div className={styles.questionRow}>
                      <img
                        className={styles.plusMinusImg}
                        src={plus}
                        alt={plus}
                        onClick={() => showHidden(5)}
                      />
                      <span className={styles.mediumTitle}>
                        <I18n t='How do I earn even more?' />
                      </span>
                    </div>
                  ) : (
                    <>
                      <div className={styles.questionRow}>
                        <img
                          className={styles.plusMinusImg}
                          src={minus}
                          alt={'minus1'}
                          onClick={() => showHidden(5)}
                        />
                        <div className={styles.mediumTitle}>
                          <I18n t='How do I earn even more?' />
                        </div>
                      </div>
                      <div className={styles.answer}>
                        <I18n t='If you can commit to more hours that’s great! The more profiles you create the more opportunities you have to earn money with us  And when you start to earn good ratings through your great work these opportunities multiply' />
                      </div>
                    </>
                  )}
                  <div className={styles.questioDivider}>
                    <Divider />
                  </div>
                </div>
              </div>
            </div>
            <div id='section17' className={styles.section17}>
              <div
                style={{ position: 'relative', top: '115px' }}
                className={
                  languageDirection === 'rtl' && isMobile
                    ? styles.rtlLanguage
                    : ''
                }
              >
                {isMobile === false ? (
                  <img
                    className={styles.image}
                    alt='section background'
                    src={section17}
                  />
                ) : (
                  <img
                    className={styles.image}
                    alt='section background'
                    src={section17Mobile}
                  />
                )}
                <div className={styles.card}>
                  <div className={styles.header}>
                    <I18n t='Download The App' />
                  </div>
                  <div className={styles.subheader}>
                    <I18n t='Professional, human, multilingual, multi tasking and multi disciplinary BPO for businesses' />
                  </div>
                  <div style={{ padding: '20px' }}>
                    <img
                      className={styles.icon}
                      src={appstore}
                      alt='appstore'
                      onClick={() =>
                        window.open(
                          'https://apps.apple.com/il/app/phonedo/id1532753329?l=iw',
                          '_blank',
                        )
                      }
                    />
                    <img
                      className={styles.icon}
                      src={googleplay}
                      alt='googleplay'
                      onClick={() =>
                        window.open(
                          'https://play.google.com/store/apps/details?id=com.riverway.phonedo&gl=IL',
                          '_blank',
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div id='section18' className={styles.section18}>
              <div
                className={
                  languageDirection === 'rtl'
                    ? styles.rtlLanguage.concat(' ').concat(styles.subsection)
                    : styles.subsection
                }
              >
                <div
                  className={
                    languageDirection === 'rtl' ? styles.cardRtl : styles.card
                  }
                  style={{ width: '50vw' }}
                >
                  <img src={logo} alt='logo' />
                  <div className={styles.subheader}>
                    <I18n t='Create better experiences with Phonedo Mobile app' />
                  </div>
                  <div className={styles.subheader}>Phone.do 2022</div>
                  <div
                    className={styles.subheaderLine}
                    onClick={() => {
                      window.open(
                        `/${window.location.pathname.slice(1, 3)}/privacy-policy`,
                      );
                    }}
                  >
                    <I18n t='Privacy Policy' />
                  </div>{' '}
                  <div
                    className={styles.subheaderLine}
                    onClick={() => {
                      window.open(
                        `/${window.location.pathname.slice(1, 3)}/termsAndCondition`,
                      );
                    }}
                  >
                    <I18n t='Terms and Conditions' />
                  </div>
                </div>
                {isMobile === false && (
                  <div style={{ width: '50vw' }}>
                    <div className={styles.subsection}>
                      <a href='#section3' className={styles.smallSubheader}>
                        <I18n t='Phone do a New Way of Doing Business' />
                      </a>
                      <a href='#section3' className={styles.smallSubheader}>
                        <I18n t='Why use Phonedo' />
                      </a>
                    </div>
                    <div className={styles.subsection}>
                      <a href='#section4' className={styles.smallSubheader}>
                        <I18n t='Create your own Dream Job!' />
                      </a>
                      <a href='#section10' className={styles.smallSubheader}>
                        <I18n t='How do you do it?' />
                      </a>
                    </div>
                    <div className={styles.subsection}>
                      <a href='#section5' className={styles.smallSubheader}>
                        <I18n t='Do What you Love - Love What you Do' />
                      </a>
                      <a href='#section12' className={styles.smallSubheader}>
                        <I18n t='Our Customers' />
                      </a>
                    </div>
                    <div className={styles.subsection}>
                      <a href='#section8' className={styles.smallSubheader}>
                        <I18n t='The Future of Doing is Here' />
                      </a>
                      <a href='#section2' className={styles.smallSubheader}>
                        <I18n t='Our partners' />
                      </a>
                    </div>
                    <div className={styles.subsection}>
                      <a href='#section16' className={styles.smallSubheader}>
                        <I18n t='Any questions?' />
                      </a>
                      <a href='#section17' className={styles.smallSubheader}>
                        <I18n t='Download the App' />
                      </a>
                    </div>
                  </div>
                )}
                <img alt='section18' src={section18} />
              </div>
            </div>
          </div>
        </div>
      )}
      <a
        className={styles.whatsApp}
        href='Https://wa.me/97239125800?text'
        target='_blank'
        rel='noreferrer'
      >
        {' '}
        <img
          className={styles.imgwhatsApp}
          src={whatsApp}
          alt='whatsApp'
        />{' '}
      </a>

      {
        //    isMobile === false ?

        <>
          {
            finalSection ? (
              <div className={styles.arrow}>
                <AiOutlineArrowUp
                  size={30}
                  color='#42C6A5'
                  onClick={() => {
                    dropNextPage(0);
                  }}
                />
              </div>
            ) : (
              <div className={styles.arrow}>
                <AiOutlineArrowDown
                  size={30}
                  color='#42C6A5'
                  onClick={() => {
                    dropNextPage();
                  }}
                />
              </div>
            )
            // <img className={styles.imgDownArrowFixed} src={downArrowIcon} alt={downArrowIcon} onClick={() => { dropNextPage() }} />
          }
        </>
        //    : null
      }
    </div>
  );
};
