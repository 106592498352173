import { ProSidebar, Menu, SidebarHeader } from 'react-pro-sidebar';
import close from '../../images/close-icon.svg';
import menuDown from '../../images/menu-down-icon.svg';
import menuUp from '../../images/menu-up-icon.svg';
import side_menu from '../../images/side-menu.svg';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import I18n from '../lingui/i18n';

export const SideBarMenu = (props: any) => {
  const [openMenu, setopenMenu] = useState(false);
  const [openPersonalInformation, setOpenPersonalInformation] = useState(
    props.title === 'Personal information' ? true : false,
  );
  const [openLanguage, setOpenLanguage] = useState(
    props.title === 'Language' ? true : false,
  );
  const [openSocialMedia, setOpenSocialMedia] = useState(
    props.title === 'Social media' ? true : false,
  );
  const [openProfessionalInformation, setOpenProfessionalInformation] =
    useState(props.title === 'Professional information' ? true : false);
  const [openPeferredWorkplace, setOpenPreferredWorkplace] = useState(
    props.subTitle === 'Choose preferred workplace' ? true : false,
  );
  const history = useHistory();
  const languageDirection =
    window.location.pathname.includes('he') ||
    window.location.pathname.includes('ar')
      ? 'rtl'
      : 'ltr';
  return (
    <>
      {openMenu === false ? (
        <img
          onClick={() => {
            setopenMenu(true);
          }}
          alt='side menu'
          src={side_menu}
          className='sideMenuIcon'
        />
      ) : (
        <ProSidebar
          className={
            languageDirection === 'rtl' ? 'sideBarMenuRtl' : 'sideBarMenu'
          }
          breakPoint='xs'
        >
          <SidebarHeader className='wrapHeaderMenu'>
            <img
              className='closeMenu'
              alt='close'
              src={close}
              onClick={() => {
                setopenMenu(false);
              }}
            />
            <div className='titleMenu'>
              <I18n t={props.title} />
            </div>
          </SidebarHeader>
          <div className='mt-2'>
            <Menu>
              <div
                className={
                  openPersonalInformation
                    ? 'subMenu currentSubMenu'
                    : 'subMenu '
                }
                onClick={(e) => {
                  history.push(
                    `/${window.location.pathname.slice(1, 3)}/registration/agent-type`,
                  );
                  setOpenPersonalInformation(!openPersonalInformation);
                }}
              >
                <img
                  className='arrowMenu'
                  alt='open'
                  src={openPersonalInformation ? menuUp : menuDown}
                />
                <I18n t='Personal information' />
              </div>
              {props.title === 'Personal information' &&
                openPersonalInformation && (
                  <div>
                    <div
                      className={
                        props.subTitle === 'Agent type'
                          ? 'menuItem currentMenuItem'
                          : 'menuItem'
                      }
                      onClick={() => {
                        history.push(
                          `/${window.location.pathname.slice(1, 3)}/registration/agent-type`,
                        );
                      }}
                    >
                      <span>
                        <I18n t='Agent type' />
                      </span>
                    </div>
                    <div
                      className={
                        props.subTitle === 'About you'
                          ? 'menuItem currentMenuItem'
                          : 'menuItem'
                      }
                      onClick={() => {
                        history.push(
                          `/${window.location.pathname.slice(1, 3)}/registration/about-you`,
                        );
                      }}
                    >
                      <I18n t='About you' />
                    </div>
                    <div
                      className={
                        props.subTitle === 'Location'
                          ? 'menuItem currentMenuItem'
                          : 'menuItem'
                      }
                      onClick={() => {
                        history.push(
                          `/${window.location.pathname.slice(1, 3)}/registration/location`,
                        );
                      }}
                    >
                      <I18n t='Location' />
                    </div>
                    <div
                      className={
                        props.subTitle === 'A short description'
                          ? 'menuItem currentMenuItem'
                          : 'menuItem'
                      }
                      onClick={() => {
                        history.push(
                          `/${window.location.pathname.slice(1, 3)}/registration/short-description`,
                        );
                      }}
                    >
                      <I18n t='A short description' />
                    </div>
                  </div>
                )}
              <div
                className={openLanguage ? 'subMenu currentSubMenu' : 'subMenu '}
                onClick={() => {
                  history.push(
                    `/${window.location.pathname.slice(1, 3)}/registration/language`,
                  );
                  setOpenLanguage(!openLanguage);
                }}
              >
                <img
                  className='arrowMenu'
                  alt='open'
                  src={openLanguage ? menuUp : menuDown}
                />
                <I18n t='Language' />
              </div>
              {props.title === 'Language' && openLanguage && (
                <div
                  className='menuItem'
                  onClick={() => {
                    history.push(
                      `/${window.location.pathname.slice(1, 3)}/registration/language`,
                    );
                  }}
                >
                  <I18n t='Choose language & accent' />
                </div>
              )}
              <div
                className={
                  openSocialMedia ? 'subMenu currentSubMenu' : 'subMenu '
                }
                onClick={() => {
                  history.push(
                    `/${window.location.pathname.slice(1, 3)}/registration/social-media`,
                  );
                  setOpenSocialMedia(!openSocialMedia);
                }}
              >
                <img
                  className='arrowMenu'
                  alt='open'
                  src={openSocialMedia ? menuUp : menuDown}
                />
                <I18n t='Social media' />
              </div>
              {props.title === 'Social media' && openSocialMedia && (
                <div
                  className={
                    props.subTitle === 'Choose social media'
                      ? 'menuItem currentMenuItem'
                      : 'menuItem'
                  }
                  onClick={() => {
                    history.push(
                      `/${window.location.pathname.slice(1, 3)}/registration/social-media`,
                    );
                  }}
                >
                  <I18n t='Choose social media' />
                </div>
              )}
              <div
                className={
                  openProfessionalInformation
                    ? 'subMenu currentSubMenu'
                    : 'subMenu '
                }
                onClick={() => {
                  history.push(
                    `/${window.location.pathname.slice(1, 3)}/registration/education-degree`,
                  );
                  setOpenProfessionalInformation(!openProfessionalInformation);
                }}
              >
                <img
                  className='arrowMenu'
                  alt='open'
                  src={openProfessionalInformation ? menuUp : menuDown}
                />
                <I18n t='Professional information' />
              </div>
              {props.title === 'Professional information' &&
                openProfessionalInformation && (
                  <div>
                    <div
                      className={
                        props.subTitle === 'Education degree'
                          ? 'menuItem currentMenuItem'
                          : 'menuItem'
                      }
                      onClick={() => {
                        history.push(
                          `/${window.location.pathname.slice(1, 3)}/registration/education-degree`,
                        );
                      }}
                    >
                      <I18n t='Education degree' />
                    </div>
                    <div
                      className={
                        props.subTitle === 'Certificates'
                          ? 'menuItem currentMenuItem'
                          : 'menuItem'
                      }
                      onClick={() => {
                        history.push(
                          `/${window.location.pathname.slice(1, 3)}/registration/certificates`,
                        );
                      }}
                    >
                      <I18n t='Certificates' />
                    </div>
                    <div
                      className={
                        props.subTitle === 'Education title'
                          ? 'menuItem currentMenuItem'
                          : 'menuItem'
                      }
                      onClick={() => {
                        history.push(
                          `/${window.location.pathname.slice(1, 3)}/registration/education-title`,
                        );
                      }}
                    >
                      <I18n t='Education title' />
                    </div>
                    <div
                      className={
                        props.subTitle === 'Profession'
                          ? 'menuItem currentMenuItem'
                          : 'menuItem'
                      }
                      onClick={() => {
                        history.push(
                          `/${window.location.pathname.slice(1, 3)}/registration/profession`,
                        );
                      }}
                    >
                      <I18n t='Profession' />
                    </div>
                    <div
                      className={
                        props.subTitle === 'Work experience'
                          ? 'menuItem currentMenuItem'
                          : 'menuItem'
                      }
                      onClick={() => {
                        history.push(
                          `/${window.location.pathname.slice(1, 3)}/registration/work-experience`,
                        );
                      }}
                    >
                      <I18n t='Work experience' />
                    </div>
                    <div
                      className={
                        props.subTitle === 'Hobbies'
                          ? 'menuItem currentMenuItem'
                          : 'menuItem'
                      }
                      onClick={() => {
                        history.push(
                          `/${window.location.pathname.slice(1, 3)}/registration/hobbies`,
                        );
                      }}
                    >
                      <I18n t='Hobbies' />
                    </div>
                  </div>
                )}
              <div
                className={
                  openPeferredWorkplace ? 'subMenu currentSubMenu' : 'subMenu '
                }
                onClick={() => {
                  history.push(
                    `/${window.location.pathname.slice(1, 3)}/registration/preferred-workplace`,
                  );
                  setOpenPreferredWorkplace(!openPeferredWorkplace);
                }}
              >
                <img
                  className='arrowMenu'
                  alt='open'
                  src={openPeferredWorkplace ? menuUp : menuDown}
                />
                <I18n t='Preferred workplace' />
              </div>
              {props.subTitle === 'Choose preferred workplace' &&
                openPeferredWorkplace && (
                  <div
                    className={
                      props.subTitle === 'Choose preferred workplace'
                        ? 'menuItem currentMenuItem'
                        : 'menuItem'
                    }
                    onClick={() => {
                      history.push(
                        `/${window.location.pathname.slice(1, 3)}/registration/preferred-workplace`,
                      );
                    }}
                  >
                    <I18n t='Choose preferred workplace' />
                  </div>
                )}
            </Menu>
          </div>
        </ProSidebar>
      )}
    </>
  );
};
