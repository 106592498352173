import { FieldRenderProps } from 'react-final-form';
import Select from 'react-select';
import '../lib.scss';
import React from 'react';

type Props<T> = FieldRenderProps<T, HTMLInputElement>;

export const InputSearchSelect = <T extends string>(props: Props<T>) => {
  const customStyles = {
    control: () => ({
      borderRadius: 8,
      textAlign: 'start' as const,
      borderStyle: 'solid',
      borderWidth: 1,
      display: 'flex',
      borderColor: '#cccccc',
      height: 55,
      paddingInlineStart: 8,
      fontSize: 14,
      width: '19.5vw',
      fontWeight: '500' as 'bold',
      '@media only screen and (max-width: 640px)': {
        height: '46px',
        width: '148px',
      },
    }),
    menu: () => ({
      borderRadius: 4,
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#cccccc',
      textAlign: 'start' as const,
      paddingTop: 20,
      zIndex: 2000,
      width: '20vw',
      fontSize: 14,
      marginTop: 2,
    }),
    input: (provided: any) => ({
      ...provided,
      fontWeight: '500' as 'bold',
    }),
    placeholder: (provided: any) => ({
      ...provided,
      fontWeight: '400' as 'normal',
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected === true ? '#F6F5FB' : 'white',
      fontWeight: state.isSelected === true ? '500' : 'normal',
      color: 'black',
      paddingRight: 15,
      paddingLeft: 15,
    }),
  };
  const { meta, input } = props;

  return (
    <>
      <Select
        styles={customStyles}
        maxMenuHeight={130}
        options={props.options}
        onChange={input.onChange}
        value={input.value}
        placeholder={props.placeholder}
      />
      {(meta.touched && meta.error) ||
        (meta.submitError && (
          <div className='submitError'>{meta.error || meta.submitError}</div>
        ))}
    </>
  );
};
