import { Field, Form } from 'react-final-form';
import { BottomBarRegistration } from '../../lib/final-form/bottom-bar-registration';
import { InputField } from '../../lib/final-form/input-field';
import { TopBarRegistration } from '../../lib/final-form/top-bar-registration';
import { RegistrationLocationForm } from '../../stores/registration-store';
import banner5 from '../../images/banner5.png';
import { observer } from 'mobx-react';
import './registration.scss';
import { useAgentStore } from '../../stores/use-agent-store';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import I18n from '../../lib/lingui/i18n';
import { InputSearchSelect } from '../../lib/final-form/select-search';
import logoButton from '../../images/content.png';

const isoCountriesLanguages = require('iso-countries-languages');

export const RegistrationLocation = observer((props: any) => {
  const { registrationStore } = useAgentStore();
  const history = useHistory();
  const [languageDirection, setLanguageDirection] = useState<string>();
  const countryList = isoCountriesLanguages.getCountries(
    window.location.pathname.substring(1, 3),
  );
  const countryValueList: string[] = Object.values(countryList);
  const countryKeysList = Object.keys(countryList);
  const countryList2: { label: string; value: string }[] = [];

  countryValueList.reduce((obj, item) => {
    countryList2.push({
      label: item,
      value: item,
    });
    return {};
  }, {});
  useEffect(() => {
    if (
      window.location.pathname.includes('he') ||
      window.location.pathname.includes('ar')
    ) {
      setLanguageDirection('rtl');
    } else {
      setLanguageDirection('lts');
    }
  }, []);
  const { location } = props;

  useEffect(() => {
    if (!registrationStore.callAgentOnboarding)
      registrationStore.getCallAgentOnBoarding();
  }, [registrationStore]);

  function checkFields(form: any) {
    if (form.address && form.city && form.country && form.postal_code) {
      return 'approve';
    }
    return null;
  }

  return (
    <div>
      <Form<RegistrationLocationForm>
        initialValues={registrationStore.registrationLocationForm}
        onSubmit={async (form: any) => {
          const i = countryValueList.indexOf(form.country.value);
          switch (form.formButton) {
            case 'next': {
              const data = {
                country:
                  typeof form.country === 'object'
                    ? isoCountriesLanguages.getCountry('en', countryKeysList[i])
                    : form.country,
                city: form.city,
                postal_code: form.postal_code,
                state: form.state ? form.state : null,
                address: form.address,
              };
              const response = await registrationStore.updateCallAgent(data);
              if (response && response.errors) {
                return response;
              }
              return history.push(
                `/${window.location.pathname.slice(1, 3)}/registration/short-description`,
              );
            }
            case 'back': {
              return history.push(
                `/${window.location.pathname.slice(1, 3)}/registration/about-you`,
              );
            }
            case 'exit': {
              const data1 = {
                country:
                  typeof form.country === 'object'
                    ? isoCountriesLanguages.getCountry('en', countryKeysList[i])
                    : form.country,
                city: form.city,
                postal_code: form.postal_code,
                state: form.state ? form.state : null,
                address: form.address,
              };
              const response = await registrationStore.updateCallAgent(data1);
              if (response && response.errors) {
                return response;
              }
              return history.push(`/${window.location.pathname.slice(1, 3)}`);
            }
          }
        }}
      >
        {({ submitError, handleSubmit, form, values }) => (
          <form
            onSubmit={handleSubmit}
            className={
              languageDirection === 'rtl'
                ? 'registrationBodyRtl'
                : 'registrationBody'
            }
          >
            <img
              src={banner5}
              className='banner'
              id='banner5'
              alt={'banner5'}
            />
            <img
              className={
                languageDirection === 'rtl' ? 'logoButtonRtl' : 'logoButton'
              }
              src={logoButton}
              alt={'logoButton'}
              onClick={() => {
                history.push('/');
              }}
            />
            <div
              className={
                languageDirection === 'rtl' ? 'formSideRtl' : 'formSide'
              }
            >
              <TopBarRegistration
                title='Personal information'
                subTitle='Location'
                withMenu={true}
                withExit={true}
                clickExit={() => form.change('formButton', 'exit')}
              />
              <div className='dataAreaWide'>
                <div className='header'>
                  <I18n t='Add location' />
                </div>
                <div className='flexForm'>
                  <div
                    className={
                      languageDirection === 'rtl' ? 'marginLeftRtl' : ''
                    }
                  >
                    <div style={{ marginBottom: '4%' }}>
                      <Field
                        name={'country'}
                        placeholder={I18n.getTranslation(location, 'Country')}
                        options={countryList2}
                        inputClass='smallInputText'
                        menuClassName={'countrySelect'}
                        component={InputSearchSelect}
                      />
                    </div>
                    <div>
                      <Field
                        name={'city'}
                        inputType={'text'}
                        placeholder={I18n.getTranslation(location, 'City')}
                        inputClass='smallInputText'
                        component={InputField}
                      />
                    </div>
                    <div>
                      <Field
                        name={'postal_code'}
                        inputType={'text'}
                        placeholder={I18n.getTranslation(
                          location,
                          'Postal code',
                        )}
                        inputClass='smallInputText'
                        component={InputField}
                      />
                    </div>
                  </div>
                  <div className='itemRight'>
                    <div>
                      <Field
                        name={'state'}
                        inputType={'text'}
                        placeholder={I18n.getTranslation(location, 'State')}
                        inputClass='smallInputText'
                        component={InputField}
                      />
                    </div>
                    <div>
                      <Field
                        name={'address'}
                        inputType={'text'}
                        placeholder={I18n.getTranslation(
                          location,
                          'Street name & number',
                        )}
                        inputClass='smallInputText'
                        component={InputField}
                      />
                    </div>
                  </div>
                </div>
                {submitError && (
                  <div className='submitError'>
                    <I18n t={submitError} />
                  </div>
                )}
              </div>
              <BottomBarRegistration
                buttonNextapprove={checkFields(values)}
                withBack={true}
                clickBack={() => form.change('formButton', 'back')}
                clickNext={() => form.change('formButton', 'next')}
              />
            </div>
          </form>
        )}
      </Form>
    </div>
  );
});
