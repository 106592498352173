import { observer } from 'mobx-react';
import { Pause } from './icons/pause';
import { ArrowForward } from './icons/Arrow-forward';
import { MuteFill } from './icons/mute-fill';
import style from './interview.module.scss';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import { MdAutorenew } from 'react-icons/md';
import Webcam from 'react-webcam';
import { useReactMediaRecorder } from 'react-media-recorder';
import './registration.scss';
import { useAgentStore } from '../../stores/use-agent-store';
import { useEffect, useState } from 'react';
import React from 'react';
import doll from '../../images/doll.png';
import { IconContext } from 'react-icons/lib';
import i18n from '../../lib/lingui/i18n';
import styles from './interview.module.scss';
import I18n from '../../lib/lingui/i18n';
import { RegistrationVideoInterview } from './registration-video-interview';

const now = new Date();
const time =
  now.toISOString().split('T')[0] +
  ' ' +
  now.getHours() +
  ':' +
  now.getMinutes() +
  ':' +
  now.getSeconds();

export const RegistrationInterview = observer((props: any) => {
  const registrationVideoInterview = new RegistrationVideoInterview();
  const { location } = props;
  const { registrationStore } = useAgentStore();
  const [index, setIndex] = useState(0);
  const [isStop, setIsStop] = useState(false);
  const [src, setSrc] = useState(
    registrationVideoInterview.getInterviewUrlByLanguage(
      registrationStore.spokenLanguage,
    ),
  );
  const [isAnswer, setIsAnswer] = useState(false);
  const [loop, setLoop] = useState(false);
  const [hoursString, setHoursString] = useState('00');
  const [minutesString, setMinutesString] = useState('00');
  const [secondsString, setSecondsString] = useState('00');
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [totalSeconds, setTotalSeconds] = useState(0);
  const [isBigDoll, setIsBigDoll] = useState(true);
  const [languageDirection, setLanguageDirection] = useState<string>();

  const history = useHistory();

  let startTime = 0;
  useEffect(() => {
    if (
      window.location.pathname.includes('he') ||
      window.location.pathname.includes('ar')
    ) {
      setLanguageDirection('rtl');
    } else {
      setLanguageDirection('ltr');
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isAnswer) {
        if (
          registrationStore.duration === registrationVideoInterview.videoLimit
        ) {
          stopRecording();
          alert('Time Up, We move on to the next question...');
          changeVideo();
        } else {
          registrationStore.totalTime += 1;
          if (isStop) {
            registrationStore.duration += 1;

            setSeconds(seconds + 1);
            setSecondsString(
              seconds > 9 ? seconds.toString() : '0' + seconds.toString(),
            );
            if (seconds > 59) {
              setMinutes(minutes + 1);
              setSeconds(0);
              setSecondsString('00');
              setMinutesString(
                minutes > 9 ? minutes.toString() : '0' + minutes.toString(),
              );

              if (minutes > 59) {
                setHours(hours + 1);
                setMinutes(0);
                setMinutesString('00');
                setHoursString(
                  hours > 9 ? hours.toString() : '0' + hours.toString(),
                );
              }
            }
          }
        }
      }
      setTotalSeconds(totalSeconds + 1);
    }, 1000);
    if (index < 1) {
      timeout(1000).then(() => {
        video.current.play();
      });
    }
    return () => clearInterval(interval);
  });

  const { pauseRecording, resumeRecording, startRecording, stopRecording } =
    useReactMediaRecorder({
      video: true,
      onStop: (blobUrl, blob) => {
        const url = URL.createObjectURL(blob);
        finishRecording(
          url,
          index + 1,
          startTime,
          registrationStore.duration,
          registrationStore.totalTime,
        );
      },
    });

  function timeout(delay: number) {
    return new Promise((res) => setTimeout(res, delay));
  }

  function startTimer() {
    setHoursString('00');
    setMinutesString('00');
    setSecondsString('00');
    setHours(1);
    setMinutes(1);
    setSeconds(1);
    registrationStore.duration = 0;
    registrationStore.totalTime = 0;
    startTime = totalSeconds;
  }

  function finishRecording(
    video: string,
    videoId: number,
    startTimeVideo: number,
    durationVideo: number,
    totalTimeVideo: number,
  ) {
    uploadInterviewData(videoId).then((videoURL) =>
      uploadVideo(videoURL, video ?? 'null').then(() => {
        addInterviewDataToList(
          videoId,
          startTimeVideo,
          durationVideo,
          totalTimeVideo,
        );
      }),
    );
  }

  function changeVideo() {
    if (
      registrationStore.isSales === true
        ? index <
          registrationVideoInterview.englishQuestionsSaleListVideos.length - 1
        : index <
          registrationVideoInterview.englishQuestionsServiceListVideos.length -
            1
    ) {
      setIsAnswer(!isAnswer);
      if (isAnswer === true) {
        const nextIndex = index + 1;
        setIndex(nextIndex);
        setSrc(
          registrationStore.isSales === true
            ? registrationVideoInterview.getInterviewSaleListUrlByLanguage(
                registrationStore.spokenLanguage,
                nextIndex,
              )
            : registrationVideoInterview.getInterviewServiceListUrlByLanguage(
                registrationStore.spokenLanguage,
                nextIndex,
              ),
        );
        setLoop(false);
        setIsStop(false);
      } else {
        setSrc(registrationVideoInterview.IDLE);
        setLoop(true);
        setIsStop(true);
      }
    } else if (
      registrationStore.isSales === true
        ? index ===
          registrationVideoInterview.englishQuestionsSaleListVideos.length - 1
        : index ===
          registrationVideoInterview.englishQuestionsServiceListVideos.length -
            1
    ) {
      history.push(`/${window.location.pathname.slice(1, 3)}`);
    }
  }

  function addInterviewDataToList(
    videoId: number,
    startTimeVideo: number,
    durationVideo: number,
    totalTimeVideo: number,
  ) {
    const interviewData = {
      id: videoId,
      question:
        registrationStore.isSales === true
          ? registrationVideoInterview.questionsSaleListString[videoId - 1]
          : registrationVideoInterview.questionsServiceListString[videoId - 1],
      start_time: startTimeVideo,
      duration: durationVideo,
      total_time: totalTimeVideo,
    };

    registrationStore.videos[videoId - 1] = interviewData;

    if (registrationStore.isSales === true) {
      if (
        registrationStore.videos.length ===
        registrationVideoInterview.questionsSaleListString.length
      ) {
        signalDone(registrationStore.videos);
      }
    } else {
      if (
        registrationStore.videos.length ===
        registrationVideoInterview.questionsServiceListString.length
      ) {
        signalDone(registrationStore.videos);
      }
    }
  }

  async function uploadInterviewData(videoId: number) {
    const response = await registrationStore.uploadInterviewData(
      registrationVideoInterview.interviewUuid,
      videoId,
    );
    if (response && response.errors) {
      return response;
    }

    const videoURL = response.url;

    return videoURL;
  }

  async function uploadVideo(uploadURL: string, filePath: string) {
    const videoBlob = await fetch(filePath).then((r) => r.blob());
    await registrationStore.uploadVideo(uploadURL, videoBlob);
  }

  async function signalDone(videosArr: any) {
    const response = await registrationStore.signalDone(
      videosArr,
      registrationVideoInterview.interviewUuid,
      registrationStore?.callAgentOnboarding?.id,
      time,
      registrationVideoInterview.interviewVersion,
      registrationStore.isSales === true
        ? registrationVideoInterview.SALES
        : registrationVideoInterview.SERVICES,
    );

    if (response && response.errors) {
      if (response.status >= 300) alert('Problem saving the recordings ');
      return response;
    }

    const videoURL = response.url;

    return videoURL;
  }

  const video = React.useRef() as React.MutableRefObject<HTMLVideoElement>;

  return (
    <div className={styles.mainBluePage}>
      <div className={styles.flexWithButton}>
        <div className={styles.flex}>
          {isBigDoll ? (
            <video
              autoPlay
              poster={doll}
              ref={video}
              onEnded={() => {
                changeVideo();
                startRecording();
                startTimer();
              }}
              className={styles.bigVideo}
              loop={loop}
              src={src}
            />
          ) : (
            <video
              autoPlay
              poster={doll}
              ref={video}
              onEnded={() => {
                changeVideo();
                startRecording();
                startTimer();
              }}
              className={styles.smallVideo}
              loop={loop}
              src={src}
            />
          )}

          <div className={styles.FlexQuestion}>
            <div
              className={styles.question}
              style={{ direction: languageDirection === 'rtl' ? 'rtl' : 'ltr' }}
            >
              {isAnswer ? (
                <span className={styles.questionText}>
                  {registrationStore.isSales === true
                    ? i18n.getTranslation(
                        location,
                        registrationVideoInterview.questionsSaleListString[
                          index
                        ],
                      )
                    : i18n.getTranslation(
                        location,
                        registrationVideoInterview.questionsServiceListString[
                          index
                        ],
                      )}
                </span>
              ) : null}
            </div>

            {isBigDoll ? (
              <Webcam className={styles.smallWebcam} />
            ) : (
              <Webcam className={styles.bigWebcam} />
            )}
          </div>
        </div>
        <div>
          {isAnswer ? (
            <span className='timer'>
              <div style={{ color: 'white' }}>
                <I18n t='Time' />:
              </div>
              {hoursString}:{minutesString}:{secondsString}
            </span>
          ) : null}
          <button
            className='autorenew'
            onClick={() => {
              setIsBigDoll(!isBigDoll);
            }}
          >
            <IconContext.Provider value={{ color: 'white', size: '12px' }}>
              <div>
                <MdAutorenew />
              </div>
            </IconContext.Provider>
          </button>
          <div>
            {isAnswer ? (
              <div className={style.flexButton}>
                <div
                  className={
                    isBigDoll
                      ? style.bottomBarRegistration
                      : style.bottomBarRegistrationSwich
                  }
                >
                  <div className='item'>
                    <button
                      onClick={() => {
                        setIsStop(!isStop);
                        isStop ? pauseRecording() : resumeRecording();
                      }}
                      //className="bottomBarRegistrationButton"
                      className={style.bottomBarRegistrationButton}
                      style={{
                        backgroundColor: isStop ? '#FC9BA1' : '#42C6A5',
                      }}
                    >
                      {isStop ? (
                        <div className={style.recordButtongap}>
                          {' '}
                          <Pause />{' '}
                          <div style={{ marginTop: '5px' }}>
                            <I18n t='Hold' />
                          </div>
                        </div>
                      ) : (
                        <div
                          className={cn(
                            style.webSitebody,
                            style.recordButtongap,
                          )}
                        >
                          <MuteFill />
                          <div style={{ marginTop: '6px' }}>
                            <I18n t='Record' />
                          </div>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
                <div
                  className={
                    isBigDoll
                      ? style.bottomBarRegistrationNext
                      : style.bottomBarRegistrationNextSwich
                  }
                >
                  <div className='item'>
                    {
                      <button
                        className={style.bottomBarRegistrationButtonNext}
                        //id={"buttonNextApprove"}
                        onClick={() => {
                          if (registrationStore.duration < 1) {
                            alert('Record again');
                            startTimer();
                          } else {
                            stopRecording();
                            changeVideo();
                          }
                        }}
                      >
                        <div
                          style={{ color: 'white' }}
                          className={style.recordButtongap}
                        >
                          <I18n t='Next step' /> <ArrowForward />{' '}
                        </div>
                      </button>
                    }
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
});
