import { Field, Form } from 'react-final-form';
// import { BottomBarRegistration } from "../../lib/final-form/bottom-bar-registration copy";
import { checkbox } from '../../lib/final-form/checkbox';
import facebook from '../../images/facebook.svg';
import instagram from '../../images/instagram.svg';
import linkedin from '../../images/linkedin.svg';
import noMedia from '../../images/noMedia.svg';
import tiktok from '../../images/tiktok.svg';
import twitter from '../../images/twitter.svg';
import whatsapp from '../../images/whatsapp.svg';
import { TopBarRegistration } from '../../lib/final-form/top-bar-registration';
import { RegistrationSocialMediaForm } from '../../stores/registration-store';
import './registration.scss';
import { useEffect, useState } from 'react';
import { InputField } from '../../lib/final-form/input-field';
import banner7 from '../../images/banner7.png';
import { observer } from 'mobx-react';
import { useAgentStore } from '../../stores/use-agent-store';
import { BottomBarRegistration } from '../../lib/final-form/bottom-bar-registration';
import { useHistory } from 'react-router-dom';
import I18n from '../../lib/lingui/i18n';
import { Media } from '../../model/authentication';
import React from 'react';
import logoButton from '../../images/content.png';

export const SocialMedia = observer((props: any) => {
  const { registrationStore } = useAgentStore();
  const { location } = props;

  const history = useHistory();
  const [mediaName, setMediaName] = useState<string[]>([]);
  const [languageDirection, setLanguageDirection] = useState<string>();

  const optionLeft: {
    id: number;
    name: string;
    text: string;
    style: string;
    media: string;
    url: string;
    place: string;
  }[] = [
    {
      id: 1,
      name: 'facebook',
      text: 'Facebook',
      style: '#E9F6FF',
      media: facebook,
      url: 'facebookUrl',
      place: 'facebook url',
    },
    {
      id: 2,
      name: 'instagram',
      text: 'Instagram',
      style: '#FCF1FF',
      media: instagram,
      url: 'instagramUrl',
      place: 'instagram url',
    },
    {
      id: 3,
      name: 'whatsapp',
      text: 'WhatsApp',
      style: '#EEFFEE',
      media: whatsapp,
      url: 'whatsappUrl',
      place: 'what’sApp number',
    },
  ];
  const optionRight: {
    id: number;
    name: string;
    text: string;
    style: string;
    media: string;
    url: string;
    place: string;
  }[] = [
    {
      id: 5,
      name: 'twitter',
      text: 'Twitter',
      style: '#FCF3D7',
      media: twitter,
      url: 'twitterUrl',
      place: 'twitter url',
    },
    {
      id: 6,
      name: 'linkedin',
      text: 'Linkedin',
      style: '#E9F6FF',
      media: linkedin,
      url: 'linkedinUrl',
      place: 'linkedin url',
    },
    {
      id: 7,
      name: 'tikTok',
      text: 'TikTok',
      style: '#FFDFE1',
      media: tiktok,
      url: 'tikTokUrl',
      place: 'tikTok url',
    },
    {
      id: 8,
      name: 'noMedia',
      text: 'I don’t have',
      style: '#F6F5FB',
      media: noMedia,
      url: '',
      place: '',
    },
  ];
  useEffect(() => {
    if (
      window.location.pathname.includes('he') ||
      window.location.pathname.includes('ar')
    ) {
      setLanguageDirection('rtl');
    } else {
      setLanguageDirection('lts');
    }
  }, []);
  useEffect(() => {
    function buildList(media: Media) {
      const nameList = [];
      if (registrationStore.registrationSocialMediaForm) {
        if (media.facebook) {
          nameList.push('facebook');
          registrationStore.registrationSocialMediaForm.facebook =
            media.facebook;
        }
        if (media.instagram) {
          nameList.push('instagram');
          registrationStore.registrationSocialMediaForm.instagram =
            media.instagram;
        }
        if (media.whatsapp) {
          nameList.push('whatsapp');
          registrationStore.registrationSocialMediaForm.whatsapp =
            media.whatsapp;
        }
        if (media.twitter) {
          nameList.push('twitter');
          registrationStore.registrationSocialMediaForm.twitter = media.twitter;
        }
        if (media.linkedIn) {
          nameList.push('linkedin');
          registrationStore.registrationSocialMediaForm.linkedin =
            media.linkedIn;
        }
        if (media.tiktok) {
          nameList.push('tikTok');
          registrationStore.registrationSocialMediaForm.tikTok = media.tiktok;
        }
      }
      setMediaName(nameList);
    }

    if (!registrationStore.callAgentOnboarding)
      registrationStore.getCallAgentOnBoarding().then(() => {
        const media1 = registrationStore.callAgentOnboarding?.media;
        if (media1) {
          buildList(media1);
        }
      });
    const media = registrationStore.callAgentOnboarding?.media;
    if (media) {
      buildList(media);
    }
  }, [registrationStore]);

  async function createCallAgentOnBoarding(form: RegistrationSocialMediaForm) {
    const mediaObj = {
      facebook: '',
      instagram: '',
      whatsapp: '',
      twitter: '',
      linkedin: '',
      tikTok: '',
    };
    const resErr = {
      hasErr: false,
      errors: {
        facebook: '',
        instagram: '',
        whatsapp: '',
        twitter: '',
        linkedin: '',
        tikTok: '',
        noMedia: '',
      },
    };
    let j = 0;
    for (j = 0; j < mediaName.length; j++) {
      switch (mediaName[j]) {
        case 'facebook': {
          if (form.facebook && form.facebook.trim() !== '') {
            mediaObj.facebook = form.facebook;
          } else {
            resErr.hasErr = true;
            resErr.errors.facebook = 'url is required';
          }
          break;
        }
        case 'instagram': {
          if (form.instagram && form.instagram.trim() !== '') {
            mediaObj.instagram = form.instagram;
          } else {
            resErr.hasErr = true;
            resErr.errors.instagram = 'url is required';
          }
          break;
        }
        case 'whatsapp': {
          if (form.whatsapp && form.whatsapp.trim() !== '') {
            mediaObj.whatsapp = form.whatsapp;
          } else {
            resErr.hasErr = true;
            resErr.errors.whatsapp = 'url is required';
          }
          break;
        }
        case 'twitter': {
          if (form.twitter && form.twitter.trim() !== '') {
            mediaObj.twitter = form.twitter;
          } else {
            resErr.hasErr = true;
            resErr.errors.twitter = 'url is required';
          }
          break;
        }
        case 'linkedin': {
          if (form.linkedin && form.linkedin.trim() !== '') {
            mediaObj.linkedin = form.linkedin;
          } else {
            resErr.hasErr = true;
            resErr.errors.linkedin = 'url is required';
          }
          break;
        }
        case 'tikTok': {
          if (form.tikTok && form.tikTok.trim() !== '') {
            mediaObj.tikTok = form.tikTok;
          } else {
            resErr.hasErr = true;
            resErr.errors.tikTok = 'url is required';
          }
          break;
        }
      }
    }
    if (j === mediaName.length && resErr.hasErr === false) {
      const response = await registrationStore.updateCallAgent({
        social_media: mediaObj,
      });
      return response;
    }
    return resErr;
  }

  async function onchange(name: string) {
    if (mediaName.indexOf(name) !== -1) {
      const list1 = mediaName.filter((item) => item !== name);
      setMediaName(list1);
    } else {
      const list = mediaName.slice();
      list.push(name);
      setMediaName(list);
    }
  }

  return (
    <div style={{ overflow: 'hidden' }}>
      <Form<RegistrationSocialMediaForm>
        initialValues={registrationStore.registrationSocialMediaForm}
        onSubmit={async (form: any) => {
          switch (form.formButton) {
            case 'next': {
              const response = await createCallAgentOnBoarding(form);
              if (response && response.errors) {
                return response.errors;
              }
              return history.push(
                `/${window.location.pathname.slice(1, 3)}/registration/education-degree`,
              );
            }
            case 'back': {
              return history.push(
                `/${window.location.pathname.slice(1, 3)}/registration/language`,
              );
            }
            case 'exit': {
              const response = await createCallAgentOnBoarding(form);
              if (response && response.errors) {
                return response;
              }
              return history.push(`/${window.location.pathname.slice(1, 3)}`);
            }
          }
        }}
      >
        {({ submitError, handleSubmit, form }) => (
          <form
            onSubmit={handleSubmit}
            className={
              languageDirection === 'rtl'
                ? 'registrationBodyRtl'
                : 'registrationBody'
            }
          >
            <img
              src={banner7}
              className='banner'
              id='banner7'
              alt={'banner7'}
            />
            <img
              className={
                languageDirection === 'rtl' ? 'logoButtonRtl' : 'logoButton'
              }
              src={logoButton}
              alt={'logoButton'}
              onClick={() => {
                history.push('/');
              }}
            />

            <div
              className={
                languageDirection === 'rtl' ? 'formSideRtl' : 'formSide'
              }
            >
              <TopBarRegistration
                title='Social media'
                withMenu={true}
                withExit={true}
                clickExit={() => form.change('formButton', 'exit')}
              />
              <div className='dataAreaWide'>
                <div className='header'>
                  <I18n t='Choose social media' />
                </div>
                <div className='subHeader'>
                  <I18n t='If you are not a member of any social network, you can skip this step and move on' />
                </div>
                <div className='flexForm flexFormScroll'>
                  <div
                    className={
                      languageDirection === 'rtl'
                        ? 'item marginLeftRtl'
                        : 'item'
                    }
                  >
                    {optionLeft.map((item, index) => (
                      <div key={item.name}>
                        <Field
                          name={item.name + '1'}
                          id={item.id}
                          inputType={'checkbox'}
                          inputClass='smallInputText checkboxPadding'
                          text={item.text}
                          imgStyle={item.style}
                          img={item.media}
                          onchange={() => onchange(item.name)}
                          checked={mediaName.indexOf(item.name) !== -1}
                          component={checkbox}
                        />
                        {mediaName.indexOf(item.name) !== -1 ? (
                          <Field
                            name={item.name}
                            inputType={'text'}
                            inputClass='smallInputText checkboxMarging'
                            inputSmallClass='smallinputfiled'
                            placeholder={I18n.getTranslation(
                              location,
                              item.place,
                            )}
                            component={InputField}
                          />
                        ) : null}
                      </div>
                    ))}
                  </div>
                  <div className='item itemRight'>
                    {optionRight.map((item, index) => (
                      <div key={item.name}>
                        {item.id === 8 ? (
                          <Field
                            name={item.name + '1'}
                            id={item.id}
                            inputType={'checkbox'}
                            inputClass='smallInputText checkboxPadding'
                            text={item.text}
                            imgStyle={item.style}
                            img={item.media}
                            checked={mediaName.length === 0}
                            onchange={() => setMediaName([])}
                            component={checkbox}
                          />
                        ) : (
                          <Field
                            name={item.name + '1'}
                            id={item.id}
                            inputType={'checkbox'}
                            inputClass='smallInputText checkboxPadding'
                            text={item.text}
                            imgStyle={item.style}
                            img={item.media}
                            checked={mediaName.indexOf(item.name) !== -1}
                            onchange={() => onchange(item.name)}
                            component={checkbox}
                          />
                        )}
                        {mediaName.indexOf(item.name) !== -1 ? (
                          <Field
                            name={item.name}
                            inputType={'text'}
                            inputSmallClass='smallinputfiled'
                            inputClass='smallInputText checkboxMarging'
                            placeholder={I18n.getTranslation(
                              location,
                              item.place,
                            )}
                            component={InputField}
                          />
                        ) : null}
                      </div>
                    ))}
                  </div>
                </div>
                {submitError && (
                  <div className='submitError'>
                    <I18n t={submitError} />
                  </div>
                )}
              </div>
              <BottomBarRegistration
                buttonNextapprove='approve'
                withBack={true}
                clickBack={() => form.change('formButton', 'back')}
                clickNext={() => form.change('formButton', 'next')}
              />
            </div>
          </form>
        )}
      </Form>
    </div>
  );
});
