import { createI18n } from 'react-router-i18n';
import messagesHe from '../../phonedo_i18n/src/he.json';
import messagesEn from '../../phonedo_i18n/src/en.json';
import messageAr from '../../phonedo_i18n/src/ar.json';
import messageHu from '../../phonedo_i18n/src/hu.json';
import messageGr from '../../phonedo_i18n/src/gr.json';
import messageRu from '../../phonedo_i18n/src/ru.json';
import messageEs from '../../phonedo_i18n/src/es.json';
import messageFr from '../../phonedo_i18n/src/fr.json';
import messageDe from '../../phonedo_i18n/src/de.json';
import messageNl from '../../phonedo_i18n/src/nl.json';
import messagePt from '../../phonedo_i18n/src/pt.json';

async function loadLocalMessages(locale) {
  const { messages } = await import(
    `@lingui/loader!../../phonedo_i18n/src/${locale}.po`
  );
  return messages;
}
const locales = [
  'en',
  'he',
  'ar',
  'hu',
  'gr',
  'ru',
  'es',
  'fr',
  'de',
  'nl',
  'pt',
];

export function getTranslation() {
  return {
    en: loadLocalMessages('en'),
    he: loadLocalMessages('he'),
  };
}
const I18n = createI18n(
  locales,
  {
    en: messagesEn,
    he: messagesHe,
    ar: messageAr,
    hu: messageHu,
    gr: messageGr,
    ru: messageRu,
    es: messageEs,
    fr: messageFr,
    de: messageDe,
    nl: messageNl,
    pt: messagePt,
  },
  'defult',
);

export default I18n;
