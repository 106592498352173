import { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import OtpInput from 'react-otp-input';
import { RegistrationSmsForm } from '../../stores/registration-store';
import banner1 from '../../images/banner1.png';
import dots2 from '../../images/dots2.svg';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import './registration.scss';
import { useAgentStore } from '../../stores/use-agent-store';
import I18n from '../../lib/lingui/i18n';
import logoButton from '../../images/content.png';
import logoPhonedo from '../../images/logoPhonedo.png';

export const RegistrationSms = observer(() => {
  const { registrationStore } = useAgentStore();
  const history = useHistory();
  const [validCode, setValidCode] = useState('');
  const [languageDirection, setLanguageDirection] = useState<string>();
  const [resenedSms, setResenedSms] = useState<string>();

  useEffect(() => {
    if (
      window.location.pathname.includes('he') ||
      window.location.pathname.includes('ar')
    ) {
      setLanguageDirection('rtl');
    } else {
      setLanguageDirection('lts');
    }
  }, []);

  async function resendSmsVerification() {
    const resendResponse = await registrationStore.resendSmsVerification();
    if (resendResponse && resendResponse.errors) {
      try {
        setResenedSms(resendResponse.errors['FINAL_FORM/form-error']);
      } catch {
        return null;
      }
    }
  }
  return (
    <div>
      <Form<RegistrationSmsForm>
        initialValues={registrationStore.registrationSmsForm}
        onSubmit={async (form: any) => {
          if (form.formButton === 'verify') {
            const response = await registrationStore.submitRegistrationSmsForm(
              form.smsVerification,
            );
            if (response) {
              if (
                response.response &&
                response.response.status >= 200 &&
                response.response.status < 300
              ) {
                setValidCode('valid');
                return history.push(
                  `/${window.location.pathname.slice(1, 3)}/registration/sign-up`,
                );
              }
              setValidCode('invalid');
              return response;
            }
          }
        }}
      >
        {({ submitError, handleSubmit, form, values }) => (
          <form
            onSubmit={handleSubmit}
            className={
              languageDirection === 'rtl'
                ? 'registrationBodyRtl'
                : 'registrationBody'
            }
          >
            <img
              src={banner1}
              className='banner'
              id='banner1'
              alt={'banner1'}
            />
            <img
              className={
                languageDirection === 'rtl' ? 'logoButtonRtl' : 'logoButton'
              }
              src={logoButton}
              alt={'logoButton'}
              onClick={() => {
                history.push('/');
              }}
            />
            <img
              className={
                languageDirection === 'rtl'
                  ? 'rtllogoPhonedo logoPhonedo'
                  : 'logoPhonedo'
              }
              src={logoPhonedo}
              alt={logoPhonedo}
              onClick={() => {
                history.push('/');
              }}
            />
            <div
              className={
                languageDirection === 'rtl'
                  ? 'dataArea formSideRtl'
                  : 'dataArea formSide'
              }
            >
              <img
                className={
                  languageDirection === 'rtl' ? 'dots2 dots2Rtl' : 'dots2'
                }
                src={dots2}
                alt={dots2}
              />
              <div className='bigHeader'>
                <I18n t='Verify your phone' />
              </div>
              <div className='subHeader'>
                <I18n t='Check your sms inbox, we have sent you the code at' />{' '}
                <span className='inputGreen'> +12057514983</span>
              </div>
              <div className='pinCode'>
                <Field name={'smsVerification'}>
                  {({ input }) => (
                    <OtpInput
                      className={
                        validCode !== ''
                          ? validCode === 'valid'
                            ? 'otpInput otpInputValid'
                            : 'otpInput otpInputInvalid'
                          : 'otpInput'
                      }
                      value={input.value}
                      onChange={input.onChange}
                      numInputs={6}
                      separator={<span>-</span>}
                    />
                  )}
                </Field>
              </div>
              {submitError && (
                <div className='submitError'>
                  <I18n t={submitError} />
                </div>
              )}
              <br />
              {resenedSms && (
                <div className='submitError'>
                  <I18n t={resenedSms.replaceAll('.', '')} />
                </div>
              )}
              <div className='subHeader sendAgain'>
                <I18n t='Did not get any code?' />{' '}
                <span
                  className='inputGreen'
                  onClick={() => resendSmsVerification()}
                >
                  {' '}
                  <I18n t='Send again' />
                </span>
              </div>
              <button
                className='buttonCreate'
                id={
                  values.smsVerification
                    ? 'buttonCreateApprove'
                    : 'buttonCreateDisable'
                }
                onClick={() => {
                  values.smsVerification
                    ? form.change('formButton', 'verify')
                    : form.change('formButton', null);
                }}
              >
                <I18n t='Verify Code' />
              </button>
            </div>
          </form>
        )}
      </Form>
    </div>
  );
});
