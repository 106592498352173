import { Field, Form } from 'react-final-form';
import { InputField } from '../../lib/final-form/input-field';
import { RegistrationSignupForm } from '../../stores/registration-store';
import banner2 from '../../images/banner2.png';
import dots2 from '../../images/dots2.svg';
import './registration.scss';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useAgentStore } from '../../stores/use-agent-store';
import { useHistory } from 'react-router';
import { FaUser, FaEnvelope, FaPhoneAlt, FaLock } from 'react-icons/fa';
import I18n from '../../lib/lingui/i18n';
import logoButton from '../../images/content.png';
import logoPhonedo from '../../images/logoPhonedo.png';

export const RegistrationSignup = observer((props: any) => {
  const { registrationStore } = useAgentStore();
  const [clickButton, setclickButton] = useState(true);
  const [languageDirection, setLanguageDirection] = useState<string>();
  const { location } = props;
  const history = useHistory();

  useEffect(() => {
    if (
      window.location.pathname.includes('he') ||
      window.location.pathname.includes('ar')
    ) {
      setLanguageDirection('rtl');
    } else {
      setLanguageDirection('lts');
    }
  }, []);

  useEffect(() => {
    const phone = registrationStore.registrationSignupForm?.telephone;
    if (!phone) {
      registrationStore.getPhoneNumber();
    }
  }, [registrationStore]);

  function checkFields(form: any) {
    if (
      form.firstName &&
      form.lastName &&
      form.email &&
      form.password &&
      clickButton
    ) {
      return true;
    }
    return false;
  }

  return (
    <div>
      <Form<RegistrationSignupForm>
        initialValues={registrationStore.registrationSignupForm}
        onSubmit={async (form: any) => {
          if (form.formButton === 'signUp' && clickButton) {
            setclickButton(false);
            const response =
              await registrationStore.submitRegistrationSignupForm(form);
            if (response && response.errors) {
              setclickButton(true);
              return response.errors;
            }
            return history.push(
              `/${window.location.pathname.slice(1, 3)}/registration/become-an-agent`,
            );
          }
        }}
      >
        {({ submitError, handleSubmit, values, form }) => (
          <form
            onSubmit={handleSubmit}
            className={
              languageDirection === 'rtl'
                ? 'registrationBodyRtl'
                : 'registrationBody'
            }
          >
            <img
              src={banner2}
              className='banner'
              id='banner2'
              alt={'banner2'}
            />
            <img
              className={
                languageDirection === 'rtl' ? 'logoButtonRtl' : 'logoButton'
              }
              src={logoButton}
              alt={'logoButton'}
              onClick={() => {
                history.push('/');
              }}
            />
            <img
              className={
                languageDirection === 'rtl'
                  ? 'rtllogoPhonedo logoPhonedo'
                  : 'logoPhonedo'
              }
              src={logoPhonedo}
              alt={logoPhonedo}
              onClick={() => {
                history.push('/');
              }}
            />
            <div
              className={
                languageDirection === 'rtl'
                  ? 'bigdataArea formSideRtl'
                  : 'bigdataArea formSide'
              }
            >
              <div className='bigHeader'>
                <I18n t='Sign Up' />
              </div>
              <div>
                <Field
                  name={'firstName'}
                  inputType={'text'}
                  placeholder={I18n.getTranslation(location, 'First name')}
                  icon={<FaUser />}
                  component={InputField}
                />
              </div>
              <div>
                <Field
                  name={'lastName'}
                  inputType={'text'}
                  placeholder={I18n.getTranslation(location, 'Last name')}
                  icon={<FaUser />}
                  component={InputField}
                />
              </div>
              <div>
                <Field
                  name={'email'}
                  inputType={'text'}
                  placeholder={I18n.getTranslation(location, 'Email address')}
                  icon={<FaEnvelope />}
                  component={InputField}
                />
              </div>
              <div>
                <Field
                  name={'password'}
                  inputType={'password'}
                  placeholder={I18n.getTranslation(location, 'Password')}
                  icon={<FaLock />}
                  component={InputField}
                />
              </div>
              <div>
                <Field
                  name={'telephone'}
                  inputType={'text'}
                  placeholder={I18n.getTranslation(location, 'Phone Number')}
                  icon={<FaPhoneAlt />}
                  disabled='disabled'
                  inputClass='inputTextDisable'
                  component={InputField}
                />
              </div>
              <button
                className={'buttonCreate'}
                id={
                  checkFields(values)
                    ? 'buttonCreateApprove'
                    : 'buttonCreateDisable'
                }
                onClick={() => {
                  checkFields(values)
                    ? form.change('formButton', 'signUp')
                    : form.change('formButton', null);
                }}
              >
                <I18n t='Sign Up' />
              </button>
              <br />
              <br />
              <div className='subHeader2 divCenter'>
                <I18n t='By signing up, you confirm that you’ve read and accepted our' />
                <span
                  className='inputGreen'
                  onClick={() => {
                    history.push(
                      `/${window.location.pathname.slice(1, 3)}/termsAndCondition`,
                    );
                  }}
                >
                  {' '}
                  <I18n t='Terms & Condition' />
                </span>{' '}
                <I18n t='and' />{' '}
                <span
                  className='inputGreen'
                  onClick={() => {
                    history.push(
                      `/${window.location.pathname.slice(1, 3)}/privacy-policy`,
                    );
                  }}
                >
                  <I18n t='Privacy Policy' />
                </span>
                .
              </div>
              <img
                className={
                  languageDirection === 'rtl' ? 'dots2 dots2Rtl' : 'dots2'
                }
                src={dots2}
                alt={dots2}
              />
              {submitError && (
                <div>
                  <I18n t={submitError} />
                </div>
              )}
            </div>
          </form>
        )}
      </Form>
    </div>
  );
});
