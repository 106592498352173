import { v4 as uuidv4 } from 'uuid';
import { ApiClient, CALLAGENT_TOKEN_KEY } from '../api/api-client';
import { handleFormSubmit } from '../lib/final-form/final-form';
import { action, observable, makeAutoObservable } from 'mobx';
import {
  CallAgentLanguage,
  CallAgentOnboardingData,
  Certificate,
  Language,
  EnumItemModel,
  Media,
  UserGenderEnum,
  UserLanguage,
} from '../model/authentication';
import dateFormat from 'dateformat';
import { parseJwt } from '../api/jwt';
import {
  educationDegreesKeys,
  educationDegreesVals,
} from '../globals/professional-information';
import Cookies from 'universal-cookie';

type JwtUser = {
  id: string;
  makeAutoObservable: any;
};

export type RegistrationPhoneForm = {
  countryCode: string;
  telephone: string;
  formButton: 'send' | null;
};

export type RegistrationSmsForm = {
  smsVerification: string;
  formButton: 'verify' | null;
};

export type RegistrationSignupForm = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  telephone: string;
  formButton: 'signUp' | null;
};

export type RegistrationAboutYouForm = {
  firstName: string;
  lastName: string;
  email: string;
  birthDate: Date | string;
  gender: string;
  countryBirth: string;
  formButton: 'exit' | 'back' | 'next' | null;
};

export type RegistrationLocationForm = {
  address: string;
  city: string;
  postal_code: string;
  country: string;
  state: string;
  formButton: 'exit' | 'back' | 'next' | null;
};

export type RegistrationLanguageForm = {
  language: { label: string; value: string };
  language2: { label: string; value: string };
  language3: { label: string; value: string };
  accent: { label: string; value: string };
  accent2: { label: string; value: string };
  accent3: { label: string; value: string };
  formButton: 'exit' | 'back' | 'next' | null;
};

export type RegistrationSocialMediaForm = {
  facebook: string;
  instagram: string;
  whatsapp: string;
  twitter: string;
  linkedin: string;
  tikTok: string;
  noMedia: string;
  formButton: 'exit' | 'back' | 'next' | null;
};
export type RegistrationEducationDegreeForm = {
  educationDegree: string;
  formButton: 'exit' | 'back' | 'next' | null;
};

export type RegistrationEducationTitleForm = {
  education: Array<EnumItemModel>;
  formButton: 'exit' | 'back' | 'next' | null;
};

export type RegistrationHobbiesForm = {
  hobbies: Array<EnumItemModel>;
  formButton: 'exit' | 'back' | 'next' | null;
};

export type RegistrationProfessionsForm = {
  professions: Array<EnumItemModel>;
  formButton: 'exit' | 'back' | 'next' | null;
};

export type RegistrationCertificatesForm = {
  id: string;
  expirationDate: Date | string;
  profession: EnumItemModel;
  jurisdiction: string;
  file: string;
  formButton: 'exit' | 'back' | 'next' | null;
};

export type RegistrationWorkExperiencForm = {
  workExperience: Array<EnumItemModel>;
  formButton: 'exit' | 'back' | 'next' | null;
};

export type RegistrationShortDescriptionForm = {
  description: string;
  formButton: 'exit' | 'back' | 'next' | null;
};

const cookies = new Cookies();
export class RegistrationStore {
  registrationSignupForm?: RegistrationSignupForm;
  registrationPhoneForm?: RegistrationPhoneForm;
  registrationSmsForm?: RegistrationSmsForm;
  registrationAboutYouForm?: RegistrationAboutYouForm;
  registrationLocationForm?: RegistrationLocationForm;
  registrationLanguageForm?: RegistrationLanguageForm;
  registrationShortDescriptionForm?: RegistrationShortDescriptionForm;
  registrationSocialMediaForm?: RegistrationSocialMediaForm;
  registrationEducationDegreeForm?: RegistrationEducationDegreeForm;
  registrationEducationTitleForm?: RegistrationEducationTitleForm;
  registrationHobbiesForm?: RegistrationHobbiesForm;
  registrationProfessionsForm?: RegistrationProfessionsForm;
  registrationWorkExperiencForm?: RegistrationWorkExperiencForm;
  registrationCertificatesForm?: RegistrationCertificatesForm;
  isSales = false;
  duration = 0;
  totalTime = 0;
  spokenLanguage = '';
  videos: any = [{}];

  private partiallyAuthanticatedJwtToken: string | null = null;
  public token?: string;
  public callAgentOnboarding?: CallAgentOnboardingData;
  public certificate?: Certificate;
  public currentAgentId?: string;
  public agentLanguages?: Array<UserLanguage>;
  public languagesList?: Array<Language>;
  public subjectList?: Array<EnumItemModel>;

  constructor(public apiClient: ApiClient) {
    makeAutoObservable(this, {
      registrationSignupForm: observable,
      token: observable,
      submitRegistrationPhoneForm: action,
      submitRegistrationSmsForm: action,
      resendSmsVerification: action,
      getPhoneNumber: action,
      updateCallAgent: action,
      submitRegistrationSignupForm: action,
    });
  }

  submitRegistrationPhoneForm = async (phone: string) => {
    const data = {
      id: uuidv4(),
      type: 'CallAgent',
      telephone: phone,
    };
    const response = await handleFormSubmit(
      this.apiClient.requestTokenResponse(data),
    );
    if (response.errors) {
      return response.errors;
    }
    const responseSmsCode = await handleFormSubmit(
      this.apiClient.requestTokenCodeResponse(response.response?.data['id']),
    );
    if (responseSmsCode.errors) {
      return responseSmsCode.errors;
    }
    this.partiallyAuthanticatedJwtToken = response.response?.data['id'];
    return responseSmsCode;
  };

  submitRegistrationSmsForm = async (smsVerification: string) => {
    if (this.partiallyAuthanticatedJwtToken) {
      const response = await handleFormSubmit(
        this.apiClient.smsVerificationCheck(
          this.partiallyAuthanticatedJwtToken,
          smsVerification,
        ),
      );
      if (response.errors) {
        return response.errors;
      }
      return response;
    } else return null;
  };

  resendSmsVerification = async () => {
    const telephone = cookies.get('phoneNumber');
    const data = {
      id: uuidv4(),
      type: 'CallAgent',
      telephone: telephone,
    };

    const response = await handleFormSubmit(
      this.apiClient.requestTokenResponse(data),
    );
    if (response.errors) {
      return response.errors;
    }
    const resnedResponse = await handleFormSubmit(
      this.apiClient.requestTokenCodeResponse(response.response?.data['id']),
    );
    if (resnedResponse.errors) {
      return resnedResponse;
    }
  };

  getPhoneNumber = () => {
    const telephone = cookies.get('phoneNumber');
    const signUpObj = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      telephone: telephone ? String(telephone) : '',
      formButton: null,
    };
    this.registrationSignupForm = signUpObj;
  };
  hasMedia = (data: Media) => {
    if (
      data &&
      (data.email ||
        data.facebook ||
        data.instagram ||
        data.linkedIn ||
        data.phoneNumber ||
        data.tiktok ||
        data.twitter ||
        data.website ||
        data.whatsapp ||
        data.youtube)
    ) {
      return true;
    }
    return false;
  };
  updateCallAgent = async (data: any) => {
    let agentId;
    let jwt;
    if (this.currentAgentId) {
      agentId = this.currentAgentId;
    } else {
      jwt = cookies.get(CALLAGENT_TOKEN_KEY);
      if (jwt) agentId = parseJwt<JwtUser>(jwt).id;
    }
    if (this.callAgentOnboarding && agentId) {
      let genderIndex = 0;
      if (data.gender)
        genderIndex =
          data.gender === 'male'
            ? UserGenderEnum.MALE
            : data.gender === 'female'
              ? UserGenderEnum.FEMALE
              : UserGenderEnum.NOT_SET;
      const agentObj = {
        givenName: data.firstname
          ? String(data.firstname)
          : this.callAgentOnboarding.givenName,
        familyName: data.lastName
          ? String(data.lastName)
          : this.callAgentOnboarding.familyName,
        email: data.email ? String(data.email) : this.callAgentOnboarding.email,
        gender: data.gender
          ? { value: genderIndex }
          : { value: this.callAgentOnboarding.gender },
        description: data.description
          ? String(data.description)
          : (this.callAgentOnboarding.description ?? ''),
        mainWorkplace: data.preferredWorkplace
          ? String(data.preferredWorkplace)
          : (this.callAgentOnboarding.mainWorkplace ?? ''),
        birthDate: data.birthDate
          ? dateFormat(data.birthDate, 'yyyy-mm-dd')
          : this.callAgentOnboarding.birthDate
            ? dateFormat(this.callAgentOnboarding.birthDate, 'yyyy-mm-dd')
            : null,
        birthCountry: data.countryBirth
          ? String(data.countryBirth)
          : (this.callAgentOnboarding.birthCountry ?? ''),
        media: data.social_media
          ? data.social_media
          : this.hasMedia(this.callAgentOnboarding.media)
            ? this.callAgentOnboarding.media
            : null,
        address:
          data.address && data.city && data.postal_code && data.country
            ? {
                addressCountry: String(data.country),
                addressLocality: String(data.city),
                streetAddress: String(data.address),
                streetNumber: String(data.address),
                postalCode: String(data.postal_code),
              }
            : this.callAgentOnboarding.address &&
                this.callAgentOnboarding.address.addressCountry &&
                this.callAgentOnboarding.address.postalCode &&
                this.callAgentOnboarding.address.addressLocality &&
                this.callAgentOnboarding.address.streetAddress
              ? this.callAgentOnboarding.address
              : null,
        educationDegree: data.educationDegree
          ? educationDegreesKeys[
              educationDegreesVals.indexOf(data.educationDegree)
            ]
          : this.callAgentOnboarding.educationDegree,
        industries: this.callAgentOnboarding.industries,
        hobbies: data.hobbies
          ? data.hobbies.map((x: any) => '/api/selection_items/' + x.id)
          : this.callAgentOnboarding.hobbies.map(
              (x) => '/api/selection_items/' + x.id,
            ),
        profession: data.professions
          ? data.professions.map((x: any) => '/api/selection_items/' + x.id)
          : this.callAgentOnboarding.profession.map(
              (x) => '/api/selection_items/' + x.id,
            ),
        education: data.education
          ? data.education.map((x: any) => '/api/selection_items/' + x.id)
          : this.callAgentOnboarding.education.map(
              (x: any) => '/api/selection_items/' + x.id,
            ),
        workExperience: data.workExperience
          ? data.workExperience.map((x: any) => '/api/selection_items/' + x.id)
          : this.callAgentOnboarding.workExperience.map(
              (x: any) => '/api/selection_items/' + x.id,
            ),
        jobType: data.timePositionType
          ? '/api/selection_items/' + data.timePositionType.id
          : null, //קוד
        religion: data.religion
          ? '/api/selection_items/' + data.religion
          : null,
        religionLevel: data.religionLevel
          ? '/api/selection_items/' + data.religionLevel
          : null,
      };
      const response = await this.apiClient.updateCallAgent(
        agentId,
        agentObj,
        jwt,
      );
      if (response.errors) {
        return response;
      }
      this.callAgentOnboarding = response.response;
      if (this.callAgentOnboarding) this.initForms(this.callAgentOnboarding);
      return response;
    }
  };

  submitRegistrationSignupForm = async (form: RegistrationSignupForm) => {
    const username = cookies.get('phoneNumber');
    const id = uuidv4();
    const data = {
      id: id,
      familyName: form.lastName,
      givenName: form.firstName,
      password: form.password,
      email: form.email,
      telephone: String(username),
    };
    const response = await handleFormSubmit(
      this.apiClient.signupAgentJwt(data),
    );
    if (response.errors) {
      return response;
    }
    this.partiallyAuthanticatedJwtToken = response.response.jwt;
    if (cookies.get('phone.do_cookies') === 'true') {
      cookies.set(CALLAGENT_TOKEN_KEY, response.response.jwt);
    }
    this.registrationSignupForm = form;
    return response;
  };

  saveImageProfile = async (file: File | undefined) => {
    let jwt;
    let agentId;
    if (this.currentAgentId) {
      agentId = this.currentAgentId;
    } else {
      jwt = cookies.get(CALLAGENT_TOKEN_KEY);
      if (jwt) agentId = parseJwt<JwtUser>(jwt).id;
    }
    const token = cookies.get(CALLAGENT_TOKEN_KEY);
    if (file) {
      const response = await handleFormSubmit(
        this.apiClient.saveImageProfile(agentId, file, token ? token : ''),
      );
      if (response.errors) {
        return response.errors;
      }
    }
  };

  getEnumByKey = async (key: string) => {
    const res = await handleFormSubmit(this.apiClient.getEnumByKey(key));
    if (res.errors) {
      return null;
    }
    return res.response[0];
  };

  getCallAgentOnBoarding = async () => {
    const jwt = cookies.get(CALLAGENT_TOKEN_KEY);
    if (jwt) {
      const agentId = parseJwt<JwtUser>(jwt).id;
      const agent = await this.apiClient.getCallAgentOnBoarding(agentId, jwt);
      this.callAgentOnboarding = {
        id: agentId,
        givenName: agent.givenName,
        familyName: agent.familyName,
        telephone: agent.telephone,
        email: agent.email,
        gender: agent.gender,
        jobType: agent.jobType,
        mainWorkplace: agent.mainWorkplace,
        description: agent.description,
        birthDate: agent.birthDate
          ? dateFormat(agent.birthDate, 'yyyy-mm-dd')
          : null,
        birthCountry: agent.birthCountry,
        address: agent.address,
        hobbies: agent.hobbies ?? [],
        languages: agent.languages ?? [],
        educationDegree: agent.educationDegree,
        education: agent.education ?? [],
        workExperience: agent.workExperience ?? [],
        profession: agent.profession ?? [],
        rating: agent.rating,
        media: agent.media,
        avatar: agent.avatar,
        balance: agent.balance,
        fcmCloudToken: agent.fcmCloudToken,
        industries: agent.industries,
        status: agent.status,
        userLocale: agent.userLocale,
        religion: agent.religion,
        religionLevel: agent.religionLevel,
      };
      this.initForms(this.callAgentOnboarding);
    }
  };

  initForms = (data: CallAgentOnboardingData) => {
    this.registrationAboutYouForm = {
      firstName: data.givenName,
      lastName: data.familyName,
      email: data.email,
      gender:
        data.gender === UserGenderEnum.FEMALE
          ? 'female'
          : data.gender === UserGenderEnum.MALE
            ? 'male'
            : '',
      birthDate: data.birthDate ?? new Date(),
      countryBirth: data.birthCountry,
      formButton: null,
    };
    this.registrationLocationForm = {
      postal_code: data.address ? data.address.postalCode : '',
      address: data.address ? data.address.streetAddress : '',
      city: data.address ? data.address.addressLocality : '',
      state: '',
      country: data.address ? data.address.addressCountry : '',
      formButton: null,
    };
    this.registrationShortDescriptionForm = {
      description: data.description,
      formButton: null,
    };
    this.registrationEducationDegreeForm = {
      educationDegree: data.educationDegree
        ? educationDegreesVals[
            educationDegreesKeys.indexOf(data.educationDegree)
          ]
        : 'None',
      formButton: null,
    };
    this.registrationEducationTitleForm = {
      education: data.education,
      formButton: null,
    };
    this.registrationHobbiesForm = {
      hobbies: data.hobbies,
      formButton: null,
    };
    this.registrationProfessionsForm = {
      professions: data.profession,
      formButton: null,
    };

    this.registrationWorkExperiencForm = {
      workExperience: data.hobbies,
      formButton: null,
    };
    if (data.media) {
      this.registrationSocialMediaForm = {
        facebook: data.media.facebook ?? '',
        instagram: data.media.instagram ?? '',
        whatsapp: data.media.whatsapp ?? '',
        twitter: data.media.twitter ?? '',
        linkedin: data.media.linkedIn ?? '',
        tikTok: data.media.tiktok ?? '',
        noMedia: '',
        formButton: null,
      };
    } else {
      this.registrationSocialMediaForm = {
        facebook: '',
        instagram: '',
        whatsapp: '',
        twitter: '',
        linkedin: '',
        tikTok: '',
        noMedia: '',
        formButton: null,
      };
    }

    const data1 = {
      language: { label: '', value: '' },
      language2: { label: '', value: '' },
      language3: { label: '', value: '' },
      accent: { label: '', value: '' },
      accent2: { label: '', value: '' },
      accent3: { label: '', value: '' },
      formButton: null,
    };
    for (let i = 0; i < data.languages.length; i++) {
      if (i === 0) {
        data1.language = {
          label: data.languages[i].languageTitle,
          value: data.languages[i].language,
        };
        data1.accent = {
          label: data.languages[i].accent.title,
          value: data.languages[i].accent.id,
        };
      } else if (i === 1) {
        data1.language2 = {
          label: data.languages[i].languageTitle,
          value: data.languages[i].language,
        };
        data1.accent2 = {
          label: data.languages[i].accent.title,
          value: data.languages[i].accent.id,
        };
      } else if (i === 2) {
        data1.language3 = {
          label: data.languages[i].languageTitle,
          value: data.languages[i].language,
        };
        data1.accent3 = {
          label: data.languages[i].accent.title,
          value: data.languages[i].accent.id,
        };
      }
    }
    this.registrationLanguageForm = data1;
  };

  async updateCallAgentLanguages(languages: Array<CallAgentLanguage>) {
    let jwt;
    let agentId;
    if (this.currentAgentId) {
      agentId = this.currentAgentId;
    } else {
      jwt = cookies.get(CALLAGENT_TOKEN_KEY);
      if (jwt) agentId = parseJwt<JwtUser>(jwt).id;
    }
    const token = cookies.get(CALLAGENT_TOKEN_KEY);
    let userLanguages: UserLanguage[];
    if (this.callAgentOnboarding) {
      userLanguages = this.callAgentOnboarding.languages;
    } else {
      userLanguages = await this.apiClient.getCallAgentLanguages(
        agentId,
        token,
      );
    }
    const newList: Object[] = [];
    let isEdit;
    let item;
    languages.forEach((lang) => {
      if (
        lang &&
        lang.name &&
        lang.accent &&
        lang.name.trim() !== '' &&
        lang.accent.trim() !== ''
      ) {
        isEdit = userLanguages.findIndex(
          (element) => element.language === lang.name,
        );
        item = {
          id: isEdit !== -1 ? userLanguages[isEdit].id : uuidv4(),
          language: lang.name,
          level: 30,
          accent: `/api/accents/${lang.accent}`,
        };
        newList.push(item);
      }
    });
    const res = await this.apiClient.updateCallAgentLanguages(
      agentId,
      newList,
      token,
    );
    if (res.errors) {
      return res;
    }
    await this.getCallAgentOnBoarding();
    return res;
  }

  async fetchLanguages() {
    const res = await handleFormSubmit(this.apiClient.fetchLanguages());
    if (res.errors) {
      return null;
    }
    this.languagesList = res.response;
    return res.response;
  }

  fetchCertificationByUserId = async (userId: string | undefined) => {
    const token = cookies.get(CALLAGENT_TOKEN_KEY);
    const response = await this.apiClient.fetchCertificationByUserId(
      userId,
      token,
    );
    if (response.errors) {
      return response.errors;
    }
    let body = [];
    body = response['hydra:member'];
    if (body.length > 0) {
      const certification = body[0];
      this.registrationCertificatesForm = {
        id: certification.id,
        expirationDate: certification.expirationDate ?? new Date(),
        profession: certification.profession,
        jurisdiction: certification.jurisdiction,
        file: certification.certificate,
        formButton: null,
      };
    }
  };
  uploadCertification = async (
    userId: string | undefined,
    file: any | undefined | null,
    type: any | undefined | null,
    expirationDate: any | undefined | null,
    jurisdiction: any | undefined | null,
    professionId: any | undefined | null,
  ) => {
    const token = cookies.get(CALLAGENT_TOKEN_KEY);
    const expirationDatetoSend =
      expirationDate.toISOString().split('T')[0] + ' 00:00';
    const response = await this.apiClient.uploadCertification(
      userId,
      file,
      type,
      expirationDatetoSend,
      jurisdiction,
      professionId,
      token,
    );
    if (response.errors) {
      return response.errors;
    }
    return response;
  };

  updateCertification = async (
    userId: string | undefined,
    certificationId: string | undefined,
    file: any | undefined | null,
    type: any,
    expirationDate: any | undefined | null,
    jurisdiction: any | undefined | null,
    professionId: any | undefined | null,
  ) => {
    const token = cookies.get(CALLAGENT_TOKEN_KEY);
    let expirationDatetoSend = expirationDate;
    if (typeof expirationDate !== 'string') {
      expirationDatetoSend =
        expirationDate?.toISOString().split('T')[0] + ' 00:00';
    }
    const response = await this.apiClient.updateCertification(
      userId,
      certificationId,
      file,
      type,
      expirationDatetoSend,
      jurisdiction,
      professionId,
      token,
    );
    if (response.errors) {
      return response.errors;
    }
    return response;
  };

  uploadInterviewData = async (interviewUuid: string, videoId: number) => {
    const data = {
      interview_id: interviewUuid,
      video_id: videoId,
    };
    const response = await this.apiClient.uploadInterviewData(data);
    if (response.errors) {
      return response.errors;
    }
    return response;
  };

  uploadVideo = async (uploadURL: string, file: any) => {
    await this.apiClient.uploadVideo(uploadURL, file).then((response) => {
      return response;
    });
  };

  signalDone = async (
    videos: {}[],
    interviewUuid: string,
    agentId?: string,
    time?: string,
    version?: number,
    role?: string,
  ) => {
    const data = {
      agent_id: agentId,
      time: time,
      videos: videos,
      version: version,
      role: role,
      id: interviewUuid,
      language: this.spokenLanguage,
    };

    const response = await this.apiClient.signalDone(data, interviewUuid);
    if (response.errors) {
      return response.errors;
    }
    await this.updateInterviewDate();
    return response;
  };

  updateInterviewDate = async () => {
    const token = cookies.get(CALLAGENT_TOKEN_KEY);
    let agentID = '';
    if (this.currentAgentId) {
      agentID = this.currentAgentId;
    } else if (token) {
      agentID = parseJwt<JwtUser>(token).id;
    }
    const now = new Date().toISOString().replace('T', ' ').substring(0, 16);
    const data2 = {
      interviewDate: now,
    };
    await this.apiClient.updateInterviewDate(agentID, data2, token);
  };
}
