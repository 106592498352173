import { v4 as uuidv4 } from 'uuid';

export class RegistrationVideoInterview {
  getInterviewUrlByLanguage(language: string) {
    switch (language) {
      case 'english':
        return this.INTRO_ENGLISH;
      case 'spanish':
        return this.INTRO_SPANISH;
      case 'hebrew':
        return this.INTRO_HEBREW;
      case 'arabic':
        return this.INTRO_ARABIC;
      case 'greek':
        return this.INTRO_GREEK;
      case 'german':
        return this.INTRO_GERMAN;
      case 'french':
        return this.INTRO_FRENCH;
      default:
        return undefined;
    }
  }

  getInterviewSaleListUrlByLanguage(language: string, index: number) {
    switch (language) {
      case 'english':
        return this.englishQuestionsSaleListVideos[index];
      case 'spanish':
        return this.spanishQuestionsSaleListVideos[index];
      case 'hebrew':
        return this.hebrewQuestionsSaleListVideos[index];
      case 'arabic':
        return this.arabicQuestionsSaleListVideos[index];
      case 'greek':
        return this.greekQuestionsSaleListVideos[index];
      case 'german':
        return this.germanQuestionsSaleListVideos[index];
      case 'french':
        return this.frenchQuestionsSaleListVideos[index];
      default:
        return undefined;
    }
  }

  getInterviewServiceListUrlByLanguage(language: string, index: number) {
    switch (language) {
      case 'english':
        return this.englishQuestionsServiceListVideos[index];
      case 'spanish':
        return this.spanishQuestionsServiceListVideos[index];
      case 'hebrew':
        return this.hebrewQuestionsServiceListVideos[index];
      case 'arabic':
        return this.arabicQuestionsServiceListVideos[index];
      case 'greek':
        return this.greekQuestionsServiceListVideos[index];
      case 'german':
        return this.germanQuestionsServiceListVideos[index];
      case 'french':
        return this.frenchQuestionsServiceListVideos[index];
      default:
        return undefined;
    }
  }

  cloudinaryURL =
    'https://storage.googleapis.com/phonedo-interviews/doll_movies/web';
  IDLE = `${this.cloudinaryURL}/idle/idle.mp4`;
  INTRO_ENGLISH = `${this.cloudinaryURL}/english/introduction/introduction.mp4`;
  INTRO_SPANISH = `${this.cloudinaryURL}/spanish/introduction/introduction.mp4`;
  INTRO_HEBREW = `${this.cloudinaryURL}/Hebrew/introduction/Introduction_He_Web.mp4`;
  INTRO_ARABIC = `${this.cloudinaryURL}/Arabs/introduction/Introduction_Ar_Web.mp4`;
  INTRO_GREEK = `${this.cloudinaryURL}/Greek/Introductions/Introduction_Grk_WEB.mp4`;
  INTRO_GERMAN = `${this.cloudinaryURL}/German/introduction/Introduction_De_Web.mp4`;
  INTRO_FRENCH = `${this.cloudinaryURL}/French/introduction/Introduction_Fr_Web.mp4`;

  englishQuestionsServiceListVideos = [
    this.INTRO_ENGLISH,
    `${this.cloudinaryURL}/english/service/service1.mp4`,
    `${this.cloudinaryURL}/english/service/service2.mp4`,
    `${this.cloudinaryURL}/english/service/service3.mp4`,
    `${this.cloudinaryURL}/english/service/service4.mp4`,
    `${this.cloudinaryURL}/english/service/service5.mp4`,
    `${this.cloudinaryURL}/english/service/service6.mp4`,
    `${this.cloudinaryURL}/english/service/service7.mp4`,
    `${this.cloudinaryURL}/english/service/service8.mp4`,
    `${this.cloudinaryURL}/english/service/service9.mp4`,
    `${this.cloudinaryURL}/english/service/service10.mp4`,
    `${this.cloudinaryURL}/english/concluding/concluding.mp4`,
  ];
  englishQuestionsSaleListVideos = [
    this.INTRO_ENGLISH,
    `${this.cloudinaryURL}/english/sale/sale1.mp4`,
    `${this.cloudinaryURL}/english/sale/sale2.mp4`,
    `${this.cloudinaryURL}/english/sale/sale3.mp4`,
    `${this.cloudinaryURL}/english/sale/sale4.mp4`,
    `${this.cloudinaryURL}/english/sale/sale5.mp4`,
    `${this.cloudinaryURL}/english/sale/sale6.mp4`,
    `${this.cloudinaryURL}/english/sale/sale7.mp4`,
    `${this.cloudinaryURL}/english/sale/sale8.mp4`,
    `${this.cloudinaryURL}/english/sale/sale9.mp4`,
    `${this.cloudinaryURL}/english/sale/sale10.mp4`,
    `${this.cloudinaryURL}/english/sale/sale11.mp4`,
    `${this.cloudinaryURL}/english/sale/sale12.mp4`,
    `${this.cloudinaryURL}/english/sale/sale13.mp4`,
    `${this.cloudinaryURL}/english/concluding/concluding.mp4`,
  ];
  spanishQuestionsServiceListVideos = [
    this.INTRO_SPANISH,
    `${this.cloudinaryURL}/spanish/service/service1.mp4`,
    `${this.cloudinaryURL}/spanish/service/service2.mp4`,
    `${this.cloudinaryURL}/spanish/service/service3.mp4`,
    `${this.cloudinaryURL}/spanish/service/service4.mp4`,
    `${this.cloudinaryURL}/spanish/service/service5.mp4`,
    `${this.cloudinaryURL}/spanish/service/service6.mp4`,
    `${this.cloudinaryURL}/spanish/service/service7.mp4`,
    `${this.cloudinaryURL}/spanish/service/service8.mp4`,
    `${this.cloudinaryURL}/spanish/service/service9.mp4`,
    `${this.cloudinaryURL}/spanish/service/service10.mp4`,
    `${this.cloudinaryURL}/spanish/concluding/concluding.mp4`,
  ];
  spanishQuestionsSaleListVideos = [
    this.INTRO_SPANISH,
    `${this.cloudinaryURL}/spanish/sale/sale1.mp4`,
    `${this.cloudinaryURL}/spanish/sale/sale2.mp4`,
    `${this.cloudinaryURL}/spanish/sale/sale3.mp4`,
    `${this.cloudinaryURL}/spanish/sale/sale4.mp4`,
    `${this.cloudinaryURL}/spanish/sale/sale5.mp4`,
    `${this.cloudinaryURL}/spanish/sale/sale6.mp4`,
    `${this.cloudinaryURL}/spanish/sale/sale7.mp4`,
    `${this.cloudinaryURL}/spanish/sale/sale8.mp4`,
    `${this.cloudinaryURL}/spanish/sale/sale9.mp4`,
    `${this.cloudinaryURL}/spanish/sale/sale10.mp4`,
    `${this.cloudinaryURL}/spanish/sale/sale11.mp4`,
    `${this.cloudinaryURL}/spanish/sale/sale12.mp4`,
    `${this.cloudinaryURL}/spanish/sale/sale13.mp4`,
    `${this.cloudinaryURL}/spanish/concluding/concluding.mp4`,
  ];
  /////////////////////////
  hebrewQuestionsServiceListVideos = [
    this.INTRO_HEBREW,
    `${this.cloudinaryURL}/Hebrew/service/Service%20Interview%20Questions_He_001_Web.mp4`,
    `${this.cloudinaryURL}/Hebrew/service/Service%20Interview%20Questions_He_002_Web.mp4`,
    `${this.cloudinaryURL}/Hebrew/service/Service%20Interview%20Questions_He_003_Web.mp4`,
    `${this.cloudinaryURL}/Hebrew/service/Service%20Interview%20Questions_He_004_Web.mp4`,
    `${this.cloudinaryURL}/Hebrew/service/Service%20Interview%20Questions_He_005_Web.mp4`,
    `${this.cloudinaryURL}/Hebrew/service/Service%20Interview%20Questions_He_006_Web.mp4`,
    `${this.cloudinaryURL}/Hebrew/service/Service%20Interview%20Questions_He_007_Web.mp4`,
    `${this.cloudinaryURL}/Hebrew/service/Service%20Interview%20Questions_He_008_Web.mp4`,
    `${this.cloudinaryURL}/Hebrew/service/Service%20Interview%20Questions_He_009_Web.mp4`,
    `${this.cloudinaryURL}/Hebrew/service/Service%20Interview%20Questions_He_010_Web.mp4`,
    `${this.cloudinaryURL}/Hebrew/concluding/Concluding_He_Web.mp4`,
  ];
  hebrewQuestionsSaleListVideos = [
    this.INTRO_HEBREW,
    `${this.cloudinaryURL}/Hebrew/sale/Sales_He_001_Web.mp4`,
    `${this.cloudinaryURL}/Hebrew/sale/Sales_He_002_Web.mp4`,
    `${this.cloudinaryURL}/Hebrew/sale/Sales_He_003_Web.mp4`,
    `${this.cloudinaryURL}/Hebrew/sale/Sales_He_004_Web.mp4`,
    `${this.cloudinaryURL}/Hebrew/sale/Sales_He_005_Web.mp4`,
    `${this.cloudinaryURL}/Hebrew/sale/Sales_He_006_Web.mp4`,
    `${this.cloudinaryURL}/Hebrew/sale/Sales_He_007_Web.mp4`,
    `${this.cloudinaryURL}/Hebrew/sale/Sales_He_008_Web.mp4`,
    `${this.cloudinaryURL}/Hebrew/sale/Sales_He_009_Web.mp4`,
    `${this.cloudinaryURL}/Hebrew/sale/Sales_He_010_Web.mp4`,
    `${this.cloudinaryURL}/Hebrew/sale/Sales_He_011_Web.mp4`,
    `${this.cloudinaryURL}/Hebrew/sale/Sales_He_012_Web.mp4`,
    `${this.cloudinaryURL}/Hebrew/sale/Sales_He_013_Web.mp4`,
    `${this.cloudinaryURL}/Hebrew/concluding/Concluding_He_Web.mp4`,
  ];

  arabicQuestionsServiceListVideos = [
    this.INTRO_ARABIC,
    `${this.cloudinaryURL}/Arabs/service/Service%20Interview%20Questions_Ar_002_Web.mp4`,
    `${this.cloudinaryURL}/Arabs/service/Service%20Interview%20Questions_Ar_001_Web.mp4`,
    `${this.cloudinaryURL}/Arabs/service/Service%20Interview%20Questions_Ar_003_Web.mp4`,
    `${this.cloudinaryURL}/Arabs/service/Service%20Interview%20Questions_Ar_004_Web.mp4`,
    `${this.cloudinaryURL}/Arabs/service/Service%20Interview%20Questions_Ar_005_Web.mp4`,
    `${this.cloudinaryURL}/Arabs/service/Service%20Interview%20Questions_Ar_006_Web.mp4`,
    `${this.cloudinaryURL}/Arabs/service/Service%20Interview%20Questions_Ar_007_Web.mp4`,
    `${this.cloudinaryURL}/Arabs/service/Service%20Interview%20Questions_Ar_008_Web.mp4`,
    `${this.cloudinaryURL}/Arabs/service/Service%20Interview%20Questions_Ar_009_Web.mp4`,
    `${this.cloudinaryURL}/Arabs/service/Service%20Interview%20Questions_Ar_010_Web.mp4`,
    `${this.cloudinaryURL}/Arabs/concluding/Concluding_Ar_Web.mp4`,
  ];
  arabicQuestionsSaleListVideos = [
    this.INTRO_ARABIC,
    `${this.cloudinaryURL}/Arabs/sale/Sales_Ar_001_Web.mp4`,
    `${this.cloudinaryURL}/Arabs/sale/Sales_Ar_002_Web.mp4`,
    `${this.cloudinaryURL}/Arabs/sale/Sales_Ar_003_Web.mp4`,
    `${this.cloudinaryURL}/Arabs/sale/Sales_Ar_004_Web.mp4`,
    `${this.cloudinaryURL}/Arabs/sale/Sales_Ar_005_Web.mp4`,
    `${this.cloudinaryURL}/Arabs/sale/Sales_Ar_006_Web.mp4`,
    `${this.cloudinaryURL}/Arabs/sale/Sales_Ar_007_Web.mp4`,
    `${this.cloudinaryURL}/Arabs/sale/Sales_Ar_008_Web.mp4`,
    `${this.cloudinaryURL}/Arabs/sale/Sales_Ar_009_Web.mp4`,
    `${this.cloudinaryURL}/Arabs/sale/Sales_Ar_010_Web.mp4`,
    `${this.cloudinaryURL}/Arabs/sale/Sales_Ar_011_Web.mp4`,
    `${this.cloudinaryURL}/Arabs/sale/Sales_Ar_012_Web.mp4`,
    `${this.cloudinaryURL}/Arabs/sale/Sales_Ar_013_Web.mp4`,
    `${this.cloudinaryURL}/Arabs/concluding/Concluding_Ar_Web.mp4`,
  ];

  greekQuestionsServiceListVideos = [
    this.INTRO_GREEK,
    `${this.cloudinaryURL}/Greek/service/Service_Interview_Questions_Grk_001_WEB.mp4`,
    `${this.cloudinaryURL}/Greek/service/Service_Interview_Questions_Grk_002_WEB.mp4`,
    `${this.cloudinaryURL}/Greek/service/Service_Interview_Questions_Grk_003_WEB.mp4`,
    `${this.cloudinaryURL}/Greek/service/Service_Interview_Questions_Grk_004_WEB.mp4`,
    `${this.cloudinaryURL}/Greek/service/Service_Interview_Questions_Grk_005_WEB.mp4`,
    `${this.cloudinaryURL}/Greek/service/Service_Interview_Questions_Grk_006_WEB.mp4`,
    `${this.cloudinaryURL}/Greek/service/Service_Interview_Questions_Grk_007_WEB.mp4`,
    `${this.cloudinaryURL}/Greek/service/Service_Interview_Questions_Grk_008_WEB.mp4`,
    `${this.cloudinaryURL}/Greek/service/Service_Interview_Questions_Grk_009_WEB.mp4`,
    `${this.cloudinaryURL}/Greek/service/Service_Interview_Questions_Grk_010_WEB.mp4`,
    `${this.cloudinaryURL}/Greek/concluding/Concluding_Grk_WEB.mp4`,
  ];
  greekQuestionsSaleListVideos = [
    this.INTRO_GREEK,
    `${this.cloudinaryURL}/Greek/sales/Sales_Grk_001_WEB.mp4`,
    `${this.cloudinaryURL}/Greek/sales/Sales_Grk_002_WEB.mp4`,
    `${this.cloudinaryURL}/Greek/sales/Sales_Grk_003_WEB.mp4`,
    `${this.cloudinaryURL}/Greek/sales/Sales_Grk_004_WEB.mp4`,
    `${this.cloudinaryURL}/Greek/sales/Sales_Grk_005_WEB.mp4`,
    `${this.cloudinaryURL}/Greek/sales/Sales_Grk_006_WEB.mp4`,
    `${this.cloudinaryURL}/Greek/sales/Sales_Grk_007_WEB.mp4`,
    `${this.cloudinaryURL}/Greek/sales/Sales_Grk_008_WEB.mp4`,
    `${this.cloudinaryURL}/Greek/sales/Sales_Grk_009_WEB.mp4`,
    `${this.cloudinaryURL}/Greek/sales/Sales_Grk_010_WEB.mp4`,
    `${this.cloudinaryURL}/Greek/sales/Sales_Grk_011_WEB.mp4`,
    `${this.cloudinaryURL}/Greek/sales/Sales_Grk_012_WEB.mp4`,
    `${this.cloudinaryURL}/Greek/sales/Sales_Grk_013_WEB.mp4`,
    `${this.cloudinaryURL}/Greek/concluding/Concluding_Grk_WEB.mp4`,
  ];

  germanQuestionsServiceListVideos = [
    this.INTRO_GERMAN,
    `${this.cloudinaryURL}/German/service/Service_De_001_Web.mp4`,
    `${this.cloudinaryURL}/German/service/Service_De_002_Web.mp4`,
    `${this.cloudinaryURL}/German/service/Service_De_003_Web.mp4`,
    `${this.cloudinaryURL}/German/service/Service_De_004_Web.mp4`,
    `${this.cloudinaryURL}/German/service/Service_De_005_Web.mp4`,
    `${this.cloudinaryURL}/German/service/Service_De_006_Web.mp4`,
    `${this.cloudinaryURL}/German/service/Service_De_007_Web.mp4`,
    `${this.cloudinaryURL}/German/service/Service_De_008_Web.mp4`,
    `${this.cloudinaryURL}/German/service/Service_De_009_Web.mp4`,
    `${this.cloudinaryURL}/German/service/Service_De_0010_Web.mp4`,
    `${this.cloudinaryURL}/German/Concluding/Concluding_De_Web.mp4`,
  ];
  germanQuestionsSaleListVideos = [
    this.INTRO_GERMAN,
    `${this.cloudinaryURL}/German/Sales/Sales_De_001_Web.mp4`,
    `${this.cloudinaryURL}/German/Sales/Sales_De_002_Web.mp4`,
    `${this.cloudinaryURL}/German/Sales/Sales_De_003_Web.mp4`,
    `${this.cloudinaryURL}/German/Sales/Sales_De_004_Web.mp4`,
    `${this.cloudinaryURL}/German/Sales/Sales_De_005_Web.mp4`,
    `${this.cloudinaryURL}/German/Sales/Sales_De_006_Web.mp4`,
    `${this.cloudinaryURL}/German/Sales/Sales_De_007_Web.mp4`,
    `${this.cloudinaryURL}/German/Sales/Sales_De_008_Web.mp4`,
    `${this.cloudinaryURL}/German/Sales/Sales_De_009_Web.mp4`,
    `${this.cloudinaryURL}/German/Sales/Sales_De_010_Web.mp4`,
    `${this.cloudinaryURL}/German/Sales/Sales_De_011_Web.mp4`,
    `${this.cloudinaryURL}/German/Sales/Sales_De_012_Web.mp4`,
    `${this.cloudinaryURL}/German/Sales/Sales_De_013_Web.mp4`,
    `${this.cloudinaryURL}/German/Concluding/Concluding_De_Web.mp4`,
  ];

  frenchQuestionsServiceListVideos = [
    this.INTRO_FRENCH,
    `${this.cloudinaryURL}/French/service/Service_Fr_001_Web.mp4`,
    `${this.cloudinaryURL}/French/service/Service_Fr_002_Web.mp4`,
    `${this.cloudinaryURL}/French/service/Service_Fr_003_Web.mp4`,
    `${this.cloudinaryURL}/French/service/Service_Fr_004_Web.mp4`,
    `${this.cloudinaryURL}/French/service/Service_Fr_005_Web.mp4`,
    `${this.cloudinaryURL}/French/service/Service_Fr_006_Web.mp4`,
    `${this.cloudinaryURL}/French/service/Service_Fr_007_Web.mp4`,
    `${this.cloudinaryURL}/French/service/Service_Fr_008_Web.mp4`,
    `${this.cloudinaryURL}/French/service/Service_Fr_009_Web.mp4`,
    `${this.cloudinaryURL}/French/service/Service_Fr_010_Web.mp4`,
    `${this.cloudinaryURL}/French/Concluding/Concluding_Fr_Web.mp4`,
  ];
  frenchQuestionsSaleListVideos = [
    this.INTRO_FRENCH,
    `${this.cloudinaryURL}/French/sale/Sales_Fr_001_Web.mp4`,
    `${this.cloudinaryURL}/French/sale/Sales_Fr_002_Web.mp4`,
    `${this.cloudinaryURL}/French/sale/Sales_Fr_003_Web.mp4`,
    `${this.cloudinaryURL}/French/sale/Sales_Fr_004_Web.mp4`,
    `${this.cloudinaryURL}/French/sale/Sales_Fr_005_Web.mp4`,
    `${this.cloudinaryURL}/French/sale/Sales_Fr_006_Web.mp4`,
    `${this.cloudinaryURL}/French/sale/Sales_Fr_007_Web.mp4`,
    `${this.cloudinaryURL}/French/sale/Sales_Fr_008_Web.mp4`,
    `${this.cloudinaryURL}/French/sale/Sales_Fr_009_Web.mp4`,
    `${this.cloudinaryURL}/French/sale/Sales_Fr_010_Web.mp4`,
    `${this.cloudinaryURL}/French/sale/Sales_Fr_011_Web.mp4`,
    `${this.cloudinaryURL}/French/sale/Sales_Fr_012_Web.mp4`,
    `${this.cloudinaryURL}/French/sale/Sales_Fr_013_Web.mp4`,
    `${this.cloudinaryURL}/French/Concluding/Concluding_Fr_Web.mp4`,
  ];
  questionsServiceListString = [
    'Repeat: "Cats and dogs hate each other"',
    'Please tell me about yourself',
    'What are the reasons you applied for this position with us?',
    'What do you think are the three most important qualities to be a good customer service representative?',
    'Tell me three traits that your previous boss would say are your strengths?',
    'Do you have experience in customer service? please share details with me',
    'A customer shouts at you that you are not available and your service is a disaster and the day want to end their contact with you, how would you response?',
    'A customer complaints about the service and you know they are right, how would you response?',
    'There are many candidates for this position Why should we choose you',
    'On a scale of one to ten, how much would you like to work for us?',
    'To finish off, is there anything you would like to tell us?',
  ];
  questionsSaleListString = [
    'Repeat: "Cats and dogs hate each other"',
    'Please tell me about yourself',
    'Why are you interested in the sales representative position with us?',
    "When you hear the term 'sales representative', what immediately jumps to mind?",
    'What do you think are the three most important qualities in order to be a good sales representative?',
    'Tell me three traits that your previous boss would say are your strengths?',
    'No one is perfect Tell me about threee traits that your previous boss would say you could improve on',
    "Do you have any sales experience? if so, please specify If you don't have sales experience, what makes you believe you would succeed in this job?",
    'A customer tells you that the price is very high How would you react?',
    "You get back to a customer who left their details and they say it's no longer relevant How would you react?",
    "You get back to a customer who left their details for the third time, and they tell you that they don't have time to talk right now How would you respond?",
    'There are many candidates for this position Why should we choose you',
    'On a scale of one to ten, how much would you like to work for us?',
    'To finish off, is there anything you would like to tell us?',
  ];
  videoLimit = 120;

  SALES = 'sales';
  SERVICES = 'services';
  interviewUuid = uuidv4();
  interviewVersion = 2;
}
