import { Form } from 'react-final-form';
import { TopBarRegistration } from '../../lib/final-form/top-bar-registration';
import './registration.scss';
import radioCheck from '../../images/radio-check.svg';
import radioUncheck from '../../images/radio-uncheck.svg';
import { BottomBarRegistration } from '../../lib/final-form/bottom-bar-registration';
import { useEffect, useState } from 'react';
import banner11 from '../../images/banner11.png';
import { observer } from 'mobx-react';
import { useAgentStore } from '../../stores/use-agent-store';
import { useHistory } from 'react-router-dom';
import I18n from '../../lib/lingui/i18n';
import logoButton from '../../images/content.png';

export const RegistrationPreInterview = observer((props: any) => {
  let isGetUserMedia: boolean;
  let getUserMediaErr: string;
  let tmpNavigator: any;
  tmpNavigator = window.navigator;

  tmpNavigator.getUserMedia(
    {
      video: true,
      audio: true,
    },

    function (localMediaStream: any) {
      isGetUserMedia = true;
    },

    function (err: any) {
      isGetUserMedia = false;
      getUserMediaErr = err;
    },
  );

  const { registrationStore } = useAgentStore();
  const history = useHistory();
  const [fullPartAgent, setfullPartAgent] = useState(0);
  const [languageDirection, setLanguageDirection] = useState<string>();

  useEffect(() => {
    if (!registrationStore.callAgentOnboarding)
      registrationStore.getCallAgentOnBoarding().then((res) => {});
  }, [registrationStore]);

  useEffect(() => {
    if (
      window.location.pathname.includes('he') ||
      window.location.pathname.includes('ar')
    ) {
      setLanguageDirection('rtl');
    } else {
      setLanguageDirection('lts');
    }
  }, []);
  return (
    <div>
      <Form
        onSubmit={async (form: any) => {
          switch (form.formButton) {
            case 'next': {
              if (isGetUserMedia === false) {
                alert(getUserMediaErr);
                return history.push(
                  `/${window.location.pathname.slice(1, 3)}/registration/pre-interview`,
                );
              }

              return history.push(
                `/${window.location.pathname.slice(1, 3)}/registration/interview`,
              );
            }
            case 'back': {
              return history.push(
                `/${window.location.pathname.slice(1, 3)}/registration/congratulations`,
              );
            }
            case 'exit': {
              return history.push(`/${window.location.pathname.slice(1, 3)}`);
            }
          }
        }}
      >
        {({ submitError, handleSubmit, form }) => (
          <form
            onSubmit={handleSubmit}
            className={
              languageDirection === 'rtl'
                ? 'registrationBodyRtl'
                : 'registrationBody'
            }
          >
            <img
              src={banner11}
              className='banner'
              id='banner11'
              alt={'banner11'}
            />
            <img
              className={
                languageDirection === 'rtl' ? 'logoButtonRtl' : 'logoButton'
              }
              src={logoButton}
              alt={'logoButton'}
              onClick={() => {
                history.push('/');
              }}
            />
            <div
              className={
                languageDirection === 'rtl' ? 'formSideRtl' : 'formSide'
              }
            >
              <TopBarRegistration
                title={'Personal information'}
                subTitle={'Agent type'}
                withMenu={true}
                withExit={true}
                clickExit={() => form.change('formButton', 'exit')}
              />
              <div className='dataAreaWide'>
                <div className='header agentTypeHeader'>
                  <I18n t='A small note before we start' />
                </div>
                <div className='descriptionSubHeader'>
                  <I18n t='After you have completed all the details and we already know a little, we will be happy to get to know you better so that we can tailor the conversations that are most suitable for you Therefore, you will now be presented with a digital character who will ask you several kindly familiar questions' />
                </div>
                <div className='descriptionSubHeader'>
                  <I18n t='Please choose what you prefer' />
                </div>
                <div className='agentTypeCardForm'>
                  <div
                    className={
                      languageDirection === 'rtl'
                        ? 'agentTypeCard agentTypeCardRtl'
                        : 'agentTypeCard'
                    }
                    onClick={() => {
                      setfullPartAgent(1);
                      registrationStore.isSales = true;
                    }}
                  >
                    <div className='agentTypeCardArea'>
                      <div className='item'>
                        <div className='header'>
                          <I18n t='Sales' />
                        </div>
                      </div>
                      <div className='item radioButton'>
                        <img
                          className='checkIcon'
                          alt='check input'
                          src={fullPartAgent === 1 ? radioCheck : radioUncheck}
                        />
                      </div>
                    </div>
                    <div className='agentTypeCardArea'>
                      <div className='subHeader'>
                        <I18n t='You will only make sales calls' />
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      languageDirection === 'rtl'
                        ? 'agentTypeCard agentTypeCardRtl'
                        : 'agentTypeCard'
                    }
                    onClick={() => {
                      setfullPartAgent(2);
                      registrationStore.isSales = false;
                    }}
                  >
                    <div className='agentTypeCardArea'>
                      <div className='item'>
                        <div className='header'>
                          <I18n t='Services' />
                        </div>
                      </div>
                      <div className='item radioButton'>
                        <img
                          className='checkIcon'
                          alt='check input'
                          src={fullPartAgent === 2 ? radioCheck : radioUncheck}
                        />
                      </div>
                    </div>
                    <div className='agentTypeCardArea'>
                      <div className='subHeader'>
                        <I18n t='You will only make services calls' />
                      </div>
                    </div>
                  </div>
                </div>
                {submitError && (
                  <div className='submitError'>
                    <I18n t={submitError} />
                  </div>
                )}
              </div>
              <BottomBarRegistration
                buttonNextapprove={fullPartAgent > 0 ? 'approve' : null}
                withBack={true}
                clickBack={() => form.change('formButton', 'back')}
                clickNext={() => form.change('formButton', 'next')}
              />
            </div>
          </form>
        )}
      </Form>
    </div>
  );
});
