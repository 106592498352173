import { Form } from 'react-final-form';
import { TopBarRegistration } from '../../lib/final-form/top-bar-registration';
import './registration.scss';
import atHome from '../../images/at-home.svg';
import workspace from '../../images/at-workspace.svg';
import office from '../../images/at-office.svg';
import outside from '../../images/at-outside.svg';
import checkBox from '../../images/check-box.svg';
import unCheck from '../../images/uncheck-box.svg';
import { BottomBarRegistration } from '../../lib/final-form/bottom-bar-registration';
import { useEffect, useState } from 'react';
import banner10 from '../../images/banner10.png';
import { observer } from 'mobx-react';
import { useAgentStore } from '../../stores/use-agent-store';
import { useHistory } from 'react-router-dom';
import I18n from '../../lib/lingui/i18n';
import logoButton from '../../images/content.png';

export const RegistrationPreferredWorkplace = observer(() => {
  const { registrationStore } = useAgentStore();
  const history = useHistory();
  const [preferredWork, setPreferredWork] = useState('home');
  const [languageDirection, setLanguageDirection] = useState<string>();

  useEffect(() => {
    if (
      window.location.pathname.includes('he') ||
      window.location.pathname.includes('ar')
    ) {
      setLanguageDirection('rtl');
    } else {
      setLanguageDirection('lts');
    }
    if (!registrationStore.callAgentOnboarding)
      registrationStore.getCallAgentOnBoarding().then(() => {
        const workplace1 = registrationStore.callAgentOnboarding?.mainWorkplace;
        if (workplace1) {
          setPreferredWork(workplace1);
        }
      });
    const workplace = registrationStore.callAgentOnboarding?.mainWorkplace;
    if (workplace) {
      setPreferredWork(workplace);
    }
  }, [registrationStore]);
  return (
    <div>
      <Form
        onSubmit={async (form: any) => {
          switch (form.formButton) {
            case 'next': {
              const response = await registrationStore.updateCallAgent({
                preferredWorkplace: preferredWork,
              });
              if (response && response.errors) {
                return response;
              }
              return history.push(
                `/${window.location.pathname.slice(1, 3)}/registration/congratulations`,
              );
            }
            case 'back': {
              return history.push(
                `/${window.location.pathname.slice(1, 3)}/registration/hobbies`,
              );
            }
            case 'exit': {
              const response = await registrationStore.updateCallAgent({
                preferredWorkplace: preferredWork,
              });
              if (response && response.errors) {
                return response;
              }
              return history.push(`/${window.location.pathname.slice(1, 3)}`);
            }
          }
        }}
      >
        {({ submitError, handleSubmit, form }) => (
          <form
            onSubmit={handleSubmit}
            className={
              languageDirection === 'rtl'
                ? 'registrationBodyRtl'
                : 'registrationBody'
            }
          >
            <img
              src={banner10}
              className='banner'
              id='banner10'
              alt={'banner10'}
            />
            <img
              className={
                languageDirection === 'rtl' ? 'logoButtonRtl' : 'logoButton'
              }
              src={logoButton}
              alt={'logoButton'}
              onClick={() => {
                history.push('/');
              }}
            />
            <div
              className={
                languageDirection === 'rtl' ? 'formSideRtl' : 'formSide'
              }
            >
              <TopBarRegistration
                title='Preferred workplace'
                subTitle='Choose preferred workplace'
                withMenu={true}
                withExit={true}
                clickExit={() => form.change('formButton', 'exit')}
              />
              <div className='dataAreaWide'>
                <div className='header'>
                  <I18n t='Add Preferred workplace' />
                </div>
                <div className='flexForm'>
                  <div className='workplaceItem'>
                    <div className='agentTypeCardForm'>
                      <div
                        className='preferredWorkCard'
                        onClick={() => setPreferredWork('home')}
                      >
                        <div className='preferredWorkCardArea'>
                          <div className='item'>
                            <div className='header'>
                              <I18n t='At Home' />
                            </div>
                          </div>
                          <div className='item radioButton'>
                            <img
                              alt='check input'
                              src={
                                preferredWork === 'home' ? checkBox : unCheck
                              }
                            />
                          </div>
                        </div>
                        <img alt='home' className='img' src={atHome} />
                      </div>
                    </div>
                    <div className='agentTypeCardForm'>
                      <div
                        className='preferredWorkCard'
                        onClick={() => setPreferredWork('shared')}
                      >
                        <div className='preferredWorkCardArea'>
                          <div className='item'>
                            <div className='header'>
                              <I18n t='Shared spaces' />
                            </div>
                          </div>
                          <div className='item radioButton'>
                            <img
                              alt='check input'
                              src={
                                preferredWork === 'shared' ? checkBox : unCheck
                              }
                            />
                          </div>
                        </div>
                        <img alt='workspace' className='img' src={workspace} />
                      </div>
                    </div>
                  </div>
                  <div className='workplaceItem'>
                    <div className='agentTypeCardForm'>
                      <div
                        className='preferredWorkCard'
                        onClick={() => setPreferredWork('office')}
                      >
                        <div className='preferredWorkCardArea'>
                          <div className='item'>
                            <div className='header'>
                              <I18n t='At my office' />
                            </div>
                          </div>
                          <div className='item radioButton'>
                            <img
                              alt='check input'
                              src={
                                preferredWork === 'office' ? checkBox : unCheck
                              }
                            />
                          </div>
                        </div>
                        <img alt='home' className='img' src={office} />
                      </div>
                    </div>
                    <div className='agentTypeCardForm'>
                      <div
                        className='preferredWorkCard'
                        onClick={() => setPreferredWork('outside')}
                      >
                        <div className='preferredWorkCardArea'>
                          <div className='item'>
                            <div className='header'>
                              <I18n t='Outside' />
                            </div>
                          </div>
                          <div className='item radioButton'>
                            <img
                              alt='check input'
                              src={
                                preferredWork === 'outside' ? checkBox : unCheck
                              }
                            />
                          </div>
                        </div>
                        <img alt='workspace' className='img' src={outside} />
                      </div>
                    </div>
                  </div>
                </div>
                {submitError && (
                  <div className='submitError'>
                    <I18n t={submitError} />
                  </div>
                )}
              </div>
              <BottomBarRegistration
                buttonNextapprove='approve'
                withBack={true}
                clickBack={() => form.change('formButton', 'back')}
                clickNext={() => form.change('formButton', 'next')}
              />
            </div>
          </form>
        )}
      </Form>
    </div>
  );
});
